.video-element {
    width:100%;
    height: auto;
    object-fit: cover;
  }

  .slick-slide{
    max-height: 700px;
  }

  .text-overlay {
    position: absolute;
    min-width: 83%;
    top: 50%; 
    left: 44%; 
    transform: translate(-50%, -50%);
    z-index: 1; 
    color: white;
    /* background-color: rgba(0, 0, 0, 0.3); */
    /* padding:10px; */
  }

  .color-overlay {
    position: absolute;
    top: 0;
    left:0px;
    width: 100%;
    height: 100%;
    z-index: 1;
  } 