.table-scrollable {
    overflow-x: auto;
    max-width: 600px;
    box-shadow: inset 0 0 5px rgba(150, 150 ,150,0.35);
    margin: auto;
  }

  .green {
    background-color: rgba(142, 184, 80,0.25);
  }

  .orange {
    background-color: rgba(219, 129, 46,0.25);
  }

  .grey {
    background-color: rgba(173, 181, 189, 0.25);
  }