  .whatsapp-button-sticky {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    z-index: 1000; /* Ensure the button is on top of other content */
  }
.wa-text{
  outline: 1px solid green;
  border-radius: 50px;
  padding: 0 5px;
  background-color: #8eb850;
}
  
  
  
  
  