@charset "UTF-8";

/*-----------------------------------------------------------------------------------

--------------------------
    CSS INDEX
--------------------------
    01. Start Default css 
    02. Start Header css 
    03. Start Hero css
    04. Start About css 
    05. Start Service css
    06. Start Features css 
    07. Start Elements css 
    08. Start Portfolio css
    09. Start Faq css 
    10. Start Product css 
    11. Start Team css 
    12. Start Testimonial css 
    13. Start Blog css 
    14. Start Contact css
    15. Start Footer css
    16. Start Home Two css
    17. Get Insurance
    18. Tabs



-----------------------------------------------------------------------------------*/
/*---==================
    01. Default css 
=================----*/
/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Manrope:wght@400;500;600;700;800&family=Nunito&display=swap");
/* Base CSS */
html {
  font-size: 100%;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
a:hover, a:focus {
  color: inherit;
  text-decoration: none;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1a1a1a;
  margin: 0px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 65px;
  line-height: 1em;
}

h2 {
  font-size: 45px;
  line-height: 52px;
}
@media (max-width: 1199px) {
  h2 {
    font-size: 36px;
    line-height: 45px;
  }
}

h3 {
  font-size: 24px;
  line-height: 35px;
}

h4 {
  font-size: 22px;
  line-height: 32px;
}

h5 {
  font-size: 18px;
  line-height: 25px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

ul, ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  color: #74787c;
  margin: 0px;
}

input, textarea {
  display: inherit;
}

img {
  max-width: 100%;
}

body {
  font-weight: normal;
  font-style: normal;
  color: #74787c;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 30px;
  overflow-x: hidden;
}

/*====== Extra CSS ======*/
.container-fluid {
  padding-left: 70px;
  padding-right: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 1199px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }

  .ml-lg-40 {
    margin-left: 40px;
  }

  .ml-lg-55 {
    margin-left: 55px;
  }

  .ml-lg-60 {
    margin-left: 60px;
  }

  .ml-lg-70 {
    margin-left: 70px;
  }

  .mr-lg-70 {
    margin-right: 70px;
  }

  .mr-lg-100 {
    margin-right: 100px;
  }

  .pl-lg-30 {
    padding-left: 30px;
  }

  .pl-lg-50 {
    padding-left: 50px;
  }

  .pl-lg-70 {
    padding-left: 70px;
  }

  .pl-lg-100 {
    padding-left: 100px;
  }

  .pr-lg-40 {
    padding-right: 40px;
  }

  .pr-lg-50 {
    padding-right: 50px;
  }

  .pr-lg-70 {
    padding-right: 70px;
  }

  .mr-minus-40 {
    margin-right: -40px;
  }

  .ml-minus-40 {
    margin-left: -40px;
  }
}
.container-1420 {
  max-width: 1450px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .container-1420 {
    max-width: 1280px;
  }
}
@media (max-width: 1199px) {
  .container-1420 {
    max-width: 100%;
  }
}

ul.social-link li {
  display: inline-block;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slick-slide {
  outline: 0;
}

.slick-slide video {
  max-width: 100%;
}


/*Lines I added to make disapora page look good. I may have to refactor to specify a class for the diaspora slide*/
.rotate-180 {
  transform: rotate(180deg);

}


.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: #000;
    border: 0;
    outline: none;
    background: rgba(142,184,80,0.3);
    border-radius: 50%;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


/* .slick-arrow.slick-next,
.slick-arrow.slick-prev{
background-color: #bed88f;
height:25px;
width: 25px;
display: inline-block;
align-items: center;
justify-content: center;
border-radius: 50%;
opacity: 0.8;
height:30px;
width:30px;
z-index: auto;
}


.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
    background-color: #8eb850;
    opacity:1;
}


.slick-prev::before,.slick-next::before{
   display:none;
    
    }

.arrows{
height:20px;
width:20px;
margin: 1px;

} */



.form_group {
  position: relative;
}

label {
  margin-bottom: 0;
}

.form_control {
  width: 100%;
  padding: 0 30px;
  height: 60px;
  border: none;
}

textarea.form_control {
  padding-top: 15px;
  display: inherit;
  height: 145px;
}

.tag-line {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #db812e;
}
.tag-line:before {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
}

.sub-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #db812e;
}
/* .sub-title:after, .sub-title:before {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
} */
/* .sub-title:before {
  content: "";
  margin-right: 10px;
}
.sub-title:after {
  content: "";
  margin-left: 10px;
} */

.section-title .sub-title {
  margin-bottom: 15px;
}
.section-title h2 {
  margin-bottom: 20px;
  letter-spacing: -0.03em;
  color: #bed88f;
}
@media (max-width: 767px) {
  .section-title h2 {
    font-size: 32px;
    line-height: 42px;
    color: #bed88f;
  }
}
.section-title.section-title-left .sub-title:after {
  display: none;
}
.section-title.section-title-right .sub-title:before {
  display: none;
}

.section-title-white h2 {
  color: #fff;
}

.footer-social-box ul.social-link li a, .footer-content .social-link li a, .contact-one_content-box .social-box ul.social-link li a, .single-team-item .img-holder .social-link li a, .single-team-item .img-holder .share-btn, .product-details-wrapper .product-info .product-cart ul li .wishlist-btn, .product-search-filter .product-search .search-btn, .faq-accordion-three .accordion-card .card-header h5:after, .project-item-four .img-holder .project-hover .hover-content .icon-btn, .project-item-three .project-content .icon-btn, .project-item-two .project-content .icon-btn i, .project-item-two .project-content .icon-btn, .project-item .img-holder .hover-content .icon-btn, .fintex-pagination ul li a, .fintex-user-section .user-wrapper .user-content form.user-form i, .partners-area .partner-arrows .slick-arrow, .job-details-wrapper .description-box ul.social-link li a, .single-job-item .job-main-content .wishlist-button a, .single-counter-item .icon, .process-left-item .icon, .features-item-two .icon-btn, .features-item-two .icon, .service-item-three .icon, .service-item-two .icon, .service-item .hover-text .icon-btn, .hero-slider-one .slick-arrow, .navigation-three .site-branding .call-button i, .header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.p-r {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.z--1 {
  z-index: -1;
}

.z-2 {
  z-index: 2;
}

.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 {
  color: #fff;
}
.text-white p {
  color: rgba(255, 255, 255, 0.7);
}

.slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*===== All Color =====*/
.yellow {
  color: #db812e;
}

.white {
  color: #fff;
}

/*===== All Bg =====*/
.black-bg {
  background-color: #1a1a1a;
}

.dark-black-bg {
  background-color: #0f0f0f;
}

.dark-blue-bg {
  background-color: #007ff4;
}

.bg-color-one {
  background-color: #101218;
}

.bg-color-two {
  background-color: #0f141b;
}

.bg-color-three {
  background-color: #f7f6f9;
}

/*===== All Button Style =====*/
button {
  border: none;
}

.main-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 50px;
  color: #fff;
  line-height: 25px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-btn:after {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-left: 10px;
}
.main-btn.btn-red {
  background-color: #db812e;
}
.main-btn.btn-red:hover {
  background-color: #0f0f0f;
  color: #fff;
}
.main-btn.btn-blue {
  background-color: #007eff;
}
.main-btn.btn-blue:hover {
  color: #fff;
  background-color: #0065cd;
}
.main-btn.btn-outline {
  outline: 2px solid #d69b6b;
  color:#8eb850
}
.main-btn.btn-outline:hover {
  color: #fff;
  background-color: #d69b6b;
}
.main-btn.btn-green {
  background-color: #48bb0f;
}
.main-btn.btn-green:hover {
  color: #fff;
  background-color: #3c990d;
}
.main-btn.btn-white {
  background-color: #fff;
  color: #db812e;
}
.main-btn.btn-white:hover {
  background-color: #1a1a1a;
  color: #fff;
}
.main-btn.btn-black {
  background-color: #0f0f0f;
  color: #fff;
}
.main-btn.btn-black:hover {
  background-color: #db812e;
}
.main-btn.filled-btn {
  padding: 14px 30px;
  color: #db812e;
  border: 1px solid rgba(26, 26, 26, 0.102);
}
.main-btn.filled-btn:hover {
  background-color: #db812e;
  color: #fff;
  border-color: transparent;
}
.main-btn.filled-white {
  color: #fff;
}
.main-btn.filled-white:hover {
  border-color: transparent;
  background-color: #fff;
  color: #db812e;
}

.btn-link {
  font-weight: 700;
  color: #db812e;
  text-decoration: underline;
}
.btn-link:after {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-left: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.btn-link:hover {
  color: #1a1a1a;
}
.btn-link:hover:after {
  margin-left: 12px;
}

/* Custom Animation */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(7px);
            transform: translateX(7px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
.animate-float-x {
  -webkit-animation-name: float-x;
  animation-name: float-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.animate-float-y {
  -webkit-animation-name: float-y;
  animation-name: float-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes float-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes float-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@-webkit-keyframes float-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
@keyframes float-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}
/*===== Nice Select =====*/
.nice-select {
  padding: 0 10px;
  border: none;
  border-radius: 0px;
  font-size: 16px;
}
.nice-select:after {
  position: absolute;
  right: 0;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
.nice-select ul.list {
  border-radius: 0;
}
.nice-select .option {
  padding: 0 10px;
}

/*
    Start Preloader css
*/
.preloader {
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 99999;
}
.preloader .loader {
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.preloader .pre-box {
  width: 50px;
  height: 50px;
  background: #db812e;
  -webkit-animation: loaderAnimate 0.5s linear infinite;
  animation: loaderAnimate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.preloader .pre-shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  -webkit-animation: loaderShadow 0.5s linear infinite;
  animation: loaderShadow 0.5s linear infinite;
}

@-webkit-keyframes loaderAnimate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -webkit-transform: translateY(9px) rotate(22.5deg);
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    -webkit-transform: translateY(9px) rotate(67.5deg);
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes loaderAnimate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    -webkit-transform: translateY(9px) rotate(22.5deg);
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    -webkit-transform: translateY(9px) rotate(67.5deg);
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(90deg);
    transform: translateY(0) rotate(90deg);
  }
}
@-webkit-keyframes loaderShadow {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}
@keyframes loaderShadow {
  50% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1);
  }
}
/*
    Start Back to top css
*/
.back-to-top {
  background: #db812e;
  border-radius: 50%;
  bottom: 30px;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 20px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  right: 30px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 337;
}
.back-to-top:hover, .back-to-top:focus {
  background: #0c1b36;
  color: #fff;
}

/*
    End scroll_up css
*/
/*====================
    End COMMON css 
======================*/
/*---==================
    01. Header css 
=================----*/
.transparent-header {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.topbar-one {
  padding: 15px 0;
  padding-left: 270px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .topbar-one {
    padding-left: 240px;
  }
}
@media (max-width: 1199px) {
  .topbar-one {
    display: none;
  }
}
.topbar-one .top-left span.text {
  font-weight: 600;
  color: #74787c;
}
.topbar-one .top-left span.text:not(:last-child):after {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  margin-left: 35px;
  margin-right: 35px;
  width: 4px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  vertical-align: middle;
}
@media (max-width: 1199px) {
  .topbar-one .top-left span.text:not(:last-child):after {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.topbar-one .top-left span.text i {
  color: #db812e;
  margin-right: 7px;
}
.topbar-one .top-left span.text span {
  font-weight: 400;
  color: #fefefe;
  margin-left: 10px;
}
.topbar-one .top-right .social-link li:not(:last-child):after {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  width: 4px;
  height: 4px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  vertical-align: middle;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.topbar-one .top-right .social-link li a:hover {
  color: #db812e;
}

.topbar-two {
  padding: 0px 20px 0px;
  background-color: #8eb850;
  color:white;
}
@media (max-width: 1199px) {
  .topbar-two {
    display: none;
  }
}
.topbar-two .top-left span.text {
  font-weight: 600;
  color: #74787c;
}
.topbar-two .top-left span.text:not(:last-child):after {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  margin-left: 35px;
  margin-right: 35px;
  width: 4px;
  height: 4px;
  background-color: rgba(26, 26, 26, 0.4);
  vertical-align: middle;
}
@media (max-width: 1199px) {
  .topbar-two .top-left span.text:not(:last-child):after {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.topbar-two .top-left span.text i {
  color: #0dceb5;
  margin-right: 7px;
}
.topbar-two .top-left span.text span {
  font-weight: 400;
  color: #1a1a1a;
  margin-left: 10px;
}
.topbar-two .top-right .social-link:after {
  display: inline-block;
  content: "";
  width: 4px;
  height: 4px;
  background-color: rgba(26, 26, 26, 0.4);
  vertical-align: middle;
  margin-left: 25px;
  margin-right: 20px;
}
.topbar-two .top-right .social-link li:not(:last-child) {
  margin-right: 20px;
}
.topbar-two .top-right .social-link li a:hover {
  color: #0dceb5;
}
.topbar-two .top-right .nice-select {
  padding-right: 15px;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  color: #1a1a1a;
}
.topbar-two .top-right .nice-select:after {
  color: #0dceb5;
}

/* Header Navigation CSS */
.primary-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.primary-menu .site-branding {
  max-width: 200px;
}

.header-navigation {
  padding: 5px 0;
}
.header-navigation .nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  z-index: 9999;
}
.header-navigation .nav-overlay.active {
  visibility: visible;
  opacity: 1;
}
.header-navigation .nav-menu .menu-button {
  display: none;
}
.header-navigation .main-menu ul > li {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .header-navigation .main-menu ul > li {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.header-navigation .main-menu ul > li.has-children > a:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
  float: right;
}
@media (max-width: 1199px) {
  .header-navigation .main-menu ul > li.has-children > a:after {
    display: none;
  }
}
.header-navigation .main-menu ul > li.has-children .sub-menu li.has-children:hover > a:after {
  color: #db812e;
}
.header-navigation .main-menu ul > li.has-children .sub-menu li.has-children > a:after {
  margin-left: 0px;
  margin-right: 5px;
  color: #1a1a1a;
}
.header-navigation .main-menu ul > li > a {
  position: relative;
  display: block;
  font-size: 18px;
  color: #8cbd53;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  padding: 10px 0;
  text-transform: capitalize;
  line-height: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .header-navigation .main-menu ul > li > a {
    font-size: 16px;
  }
}
.header-navigation .main-menu ul > li > a:before {
  position: absolute;
  bottom: 5px;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background-color: #db812e;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.header-navigation .main-menu ul > li > a.active {
  color: #db812e;
}
.header-navigation .main-menu ul > li .sub-menu {
  position: absolute;
  left: 0;
  top: 120%;
  min-width: 240px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  z-index: 99;
  padding: 15px 0;
  height: auto;
  text-align: left;
  border-radius: 5px;
  -webkit-box-shadow: 0 30px 70px 0 rgba(137, 139, 142, 0.15);
  box-shadow: 0 30px 70px 0 rgba(137, 139, 142, 0.15);
}
.header-navigation .main-menu ul > li .sub-menu li {
  display: block;
  margin: 0;
}
.header-navigation .main-menu ul > li .sub-menu li:last-child > a {
  border-bottom: none;
}
.header-navigation .main-menu ul > li .sub-menu li a {
  display: block;
  padding: 0 20px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0;
  line-height: 2.7;
  margin: 0;
  font-size: 16px;
  color: #8cbd53;
}
.header-navigation .main-menu ul > li .sub-menu li a:before {
  display: none;
}
.header-navigation .main-menu ul > li .sub-menu li a:hover {
  color: #db812e;
}
.header-navigation .main-menu ul > li .sub-menu li .sub-menu {
  left: 100%;
  top: 50%;
  min-width: 10px;
}
.header-navigation .main-menu ul > li .sub-menu li:hover .sub-menu {
  top: 0%;
}
.header-navigation .main-menu ul > li .sub-menu li:hover > a {
  color: #db812e;
}
.header-navigation .main-menu ul > li:hover > a {
  color: #db812e;
}
.header-navigation .main-menu ul > li:hover > a:before {
  width: 100%;
}
.header-navigation .main-menu ul > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header-navigation .main-menu ul > li .dd-trigger {
  display: none;
}
@media (max-width: 767px) {
  .header-navigation .nav-right-item .menu-button, .header-navigation .nav-right-item .call-button {
    display: none;
  }
}
.header-navigation.breakpoint-on .nav-menu {
  text-align: left;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 9999;
  width: 300px;
  height: 100%;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  padding: 40px 20px;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
}
.header-navigation.breakpoint-on .nav-menu.menu-on {
  left: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li {
  display: block;
  margin: 0;
  border-bottom: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li:last-child {
  border-bottom: 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li.active .sub-menu {
  border-top: 1px solid #ececec;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li a {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  color: #1a1a1a;
  padding: 15px 0;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li a:before {
  display: none;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  visibility: visible;
  opacity: 1;
  display: none;
  -webkit-transition: none;
  transition: none;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu > li > a {
  color: #1a1a1a;
  padding: 0px 20px 0 30px;
  line-height: 45px !important;
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .sub-menu > li > a:hover {
  border-color: rgba(255, 255, 255, 0.5);
}
.header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  height: 25px;
  width: 25px;
  border-radius: 3px;
  z-index: 2;
  background-color: #db812e;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  text-align: center;
}
.header-navigation.breakpoint-on .nav-menu .main-menu.menu-on {
  left: 0;
}
@media (max-width: 767px) {
  .header-navigation.breakpoint-on .nav-menu .menu-button {
    display: block;
  }
}
.header-navigation.breakpoint-on .navbar-toggler {
  display: block;
}
.header-navigation .navbar-toggler {
  padding: 10px 7px;
  background-color: #afd553;
  border-radius: 8px;
  cursor: pointer;
  display: none;
  margin-left: 30px;
}
.header-navigation .navbar-toggler span {
  position: relative;
  background-color: #f7700f;
  display: block;
  height: 1px;
  padding: 0;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  width: 20px;
  cursor: pointer;
  display: block;
}
.header-navigation .navbar-toggler span:not(:first-child) {
  margin-top: 5px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(1) {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
  top: 7px;
}
.header-navigation .navbar-toggler.active span:nth-of-type(2) {
  opacity: 0;
}
.header-navigation .navbar-toggler.active span:nth-of-type(3) {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
  top: -7px;
}

/* Navigation White */
.navigation-white.header-navigation .main-menu ul > li > a {
  color: #28a745;
}
.navigation-white.header-navigation .main-menu ul > li:hover > a {
  color: #db812e;
}
.navigation-white.header-navigation .navbar-toggler {
  border: 1px solid rgba(255, 255, 255, 0.6);
}
.navigation-white.header-navigation .navbar-toggler span {
  background-color: #fff;
}
.navigation-white.header-navigation.sticky .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}
.navigation-white.header-navigation.sticky .navbar-toggler span {
  background-color: #1a1a1a;
}

/* Navigation Default */
.navigation-default .primary-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* Navigation One */
.navigation-one .site-branding {
  margin-top: -55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-one .site-branding {
    margin-top: -25px;
  }
}
@media (max-width: 1199px) {
  .navigation-one .site-branding {
    margin-top: 0;
  }
}
.navigation-one .site-branding .default-sticky-logo {
  display: none;
}
.navigation-one .nav-inner-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin-left: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-one .nav-inner-menu {
    margin-left: 30px;
  }
}
@media (max-width: 1199px) {
  .navigation-one .nav-inner-menu {
    margin-left: 0;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.navigation-one .nav-right-item .call-button {
  margin-right: 35px;
}
.navigation-one .nav-right-item .call-button span {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.navigation-one .nav-right-item .call-button span:hover {
  color: #db812e;
}
.navigation-one .nav-right-item .call-button span i {
  margin-right: 10px;
  color: #db812e;
  font-size: 30px;
}
.navigation-one .main-menu ul > li > a.active {
  color: #db812e;
}
.navigation-one.sticky .site-branding {
  margin-top: 0;
}
.navigation-one.sticky .site-branding .default-logo {
  display: none;
}
.navigation-one.sticky .site-branding .default-sticky-logo {
  display: block;
}
.navigation-one.sticky .main-menu ul > li > a {
  color: #1a1a1a;
}
.navigation-one.sticky .main-menu ul > li > a.active {
  color: #db812e;
}
.navigation-one.sticky .nav-right-item .call-button span {
  color: #1a1a1a;
}
.navigation-one.sticky .nav-right-item .call-button span:hover {
  color: #db812e;
}

.navigation-two .primary-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navigation-two .nav-inner-menu {
  margin-left: 150px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-two .nav-inner-menu {
    margin-left: 90px;
  }
}
@media (max-width: 1199px) {
  .navigation-two .nav-inner-menu {
    margin-left: 0;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
.navigation-two .nav-right-item .sign-in-button {
  margin-right: 25px;
}

.navigation-three .primary-menu {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navigation-three .site-branding {
  max-width: inherit;
}
.navigation-three .site-branding .call-button:hover span {
  color: #48bb0f;
}
.navigation-three .site-branding .call-button:before {
  display: inline-block;
  content: "|";
  color: #d69b6b;
  margin-left: 70px;
  margin-right: 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-three .site-branding .call-button:before {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.navigation-three .site-branding .call-button i {
  width: 50px;
  height: 50px;
  border: 1px solid #48bb0f;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 50%;
}
.navigation-three .site-branding .call-button span {
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 800;
  margin-left: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 1199px) {
  .navigation-three .site-branding .call-button {
    display: none;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-three .main-menu ul > li {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.navigation-three .nav-inner-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navigation-three .nav-right-item {
  margin-left: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .navigation-three .nav-right-item {
    margin-left: 10px;
  }
}

/* Header Sticky */
.header-navigation.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  -webkit-animation: sticky 1.2s;
  animation: sticky 1.2s;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(110, 104, 162, 0.1);
  box-shadow: 0px 10px 30px 0px rgba(110, 104, 162, 0.1);
}

@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
/*---==================
    03. Hero css 
=================----*/
@-webkit-keyframes rotatecircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotatecircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.hero-slider-one .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #1a1a1a;
}
.hero-slider-one .slick-arrow.prev {
  left: 10px;
}
.hero-slider-one .slick-arrow.next {
  right: 10px;
}

.hero-content h1 span{
  color:#8EB850
}

.hero-content span.tag-line {
  margin-bottom: 25px;
}
.hero-content h1 {
  font-size: 90px;
  line-height: 1em;
  margin-bottom: 40px;
  letter-spacing: -0.05em;
  color: #8eb850;
}
@media (max-width: 1199px) {
  .hero-content h1 {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 450px) {
  .hero-content h1 {
    font-size: 32px;
    line-height: 45px;
  }
}
.hero-content .hero-button a {
  margin-bottom: 10px;
}
.hero-content .hero-button a:not(:first-child) {
  margin-left: 10px;
}
@media (max-width: 450px) {
  .hero-content .hero-button a:not(:first-child) {
    margin-left: 0;
  }
}
.hero-content .hero-button a.main-btn.filled-btn {
  padding: 13px 30px;
  border: 2px solid rgba(255, 255, 255, 0.102);
}

.banner-one {
  padding: 50px 0 30px;
}
/* @media (max-width: 767px) {
  .banner-one {
    padding: 230px 0 175px;
  }
} */
.banner-one .shape {
  position: absolute;
  bottom: 0;
}
.banner-one .shape.shape-one {
  left: 0;
}
.banner-one .shape.shape-two {
  right: 10%;
  bottom: 35%;
}
@media (max-width: 1199px) {
  .banner-one .shape.shape-two {
    display: none;
  }
}
.banner-one .shape.shape-two span {
  -webkit-animation: rotatecircle infinite 8s linear;
          animation: rotatecircle infinite 8s linear;
}
.banner-one .hero-content .hero-button .main-btn.btn-red:hover {
  background-color: #fff;
  color: #db812e;
}

.banner-two .single-slider {
  position: relative;
  padding: 150px 0;
  z-index: 1;
}
.banner-two .single-slider .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transition: all 1.4s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.banner-two .single-slider .image-layer:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(24, 28, 34, 0.4);
  mix-blend-mode: multiply;
  z-index: -1;
}
.banner-two .single-slider.slick-current .image-layer {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.banner-two .hero-content {
  position: relative;
  padding: 75px 0 80px;
  z-index: 1;
}
.banner-two .hero-content .shape-bg {
  position: absolute;
  top: 0;
  left: -90px;
  content: "";
  width: 220px;
  height: 100%;
  background-color: #0dceb5;
  z-index: -1;
}
.banner-two .hero-content span.tag-line {
  color: #fff;
}
.banner-two .hero-content .hero-button .main-btn.btn-white {
  color: #1a1a1a;
}

.banner-three {
  padding: 170px 0 110px;
}
.banner-three .shape {
  position: absolute;
  z-index: -1;
}
.banner-three .shape.shape-one {
  bottom: 3%;
  left: 5%;
}
.banner-three .shape.shape-two {
  bottom: 0%;
  left: 7%;
}
.banner-three .shape.shape-three {
  top: 0;
  left: 13%;
}
.banner-three .shape.shape-four {
  top: 170px;
  right: 40%;
}
.banner-three .shape.shape-five {
  top: 230px;
  right: 17%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-three .shape.shape-five {
    right: 7%;
  }
}
@media (max-width: 991px) {
  .banner-three .shape.shape-five {
    top: auto;
    right: 20%;
    bottom: 150px;
  }
}
.banner-three .shape.shape-five span {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #007eff;
  -webkit-filter: blur(100px);
          filter: blur(100px);
}
@media (max-width: 1199px) {
  .banner-three .shape.shape-five span {
    width: 350px;
    height: 350px;
  }
}
@media (max-width: 767px) {
  .banner-three .shape.shape-five span {
    width: 300px;
    width: 300px;
  }
}
.banner-three .shape.shape-six {
  top: 230px;
  right: 15%;
}
.banner-three .shape.shape-six span {
  -webkit-animation: rotatecircle infinite 8s linear;
          animation: rotatecircle infinite 8s linear;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-three .shape.shape-six {
    right: 7%;
  }
}
.banner-three .hero-content h1 {
  padding-right: 16%;
  font-size: 70px;
  line-height: 75px;
  color: #fff;
  letter-spacing: -0.05px;
}
@media (max-width: 1199px) {
  .banner-three .hero-content h1 {
    padding-right: 0;
    font-size: 52px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-three .hero-content h1 {
    padding-right: 20%;
  }
}
@media (max-width: 450px) {
  .banner-three .hero-content h1 {
    font-size: 42px;
    line-height: 55px;
  }
}
.banner-three .hero-content p {
  padding-right: 20%;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .banner-three .hero-content {
    margin-bottom: 50px;
  }
}
.banner-three .hero-img-box .hero-img {
  max-width: 730px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-three .hero-img-box .hero-img {
    max-width: 630px;
  }
}
@media (max-width: 1199px) {
  .banner-three .hero-img-box .hero-img {
    max-width: 100%;
  }
}

.banner-four {
  background-color: #f5f5f8;
  /* padding: 140px 0 50px; */
  overflow: hidden;
}
@media (max-width: 1199px) {
  .banner-four {
    /* padding: 240px 0 130px; */
    max-height: 600px;
  }
  .video-element{
    min-height: 600px;
  }
}
.banner-four .shape {
  position: absolute;
  z-index: -1;
}
.banner-four .shape.shape-one {
  top: 0;
  left: 0;
}
.banner-four .shape.shape-two {
  bottom: 0;
  left: 0;
}
.banner-four .shape.shape-three {
  top: 0;
  right: 0;
}
.banner-four .shape.shape-four {
  bottom: 80px;
  right: 37%;
}
.banner-four .shape.shape-blur {
  left: 17%;
  top: 27%;
}
.banner-four .shape.shape-blur span {
  width: 385px;
  height: 385px;
  border-radius: 50%;
  background-color: #d69b6b;
  -webkit-filter: blur(270px);
  filter: blur(270px);
}
.banner-four .shape-dot {
  position: absolute;
}
.banner-four .shape-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.banner-four .shape-dot.shape-dot-one {
  left: 26.4%;
  top: 18%;
}
.banner-four .shape-dot.shape-dot-one span {
  background-color: #ffba00;
}
.banner-four .shape-dot.shape-dot-two {
  top: 15%;
  left: 45%;
}
.banner-four .shape-dot.shape-dot-two span {
  width: 7px;
  height: 7px;
  background-color: #159790;
}
.banner-four .shape-dot.shape-dot-three {
  left: 13%;
  bottom: 17%;
}
.banner-four .shape-dot.shape-dot-three span {
  background-color: #18a0ca;
}
.banner-four .shape-dot.shape-dot-four {
  left: 48%;
  bottom: 12%;
}
.banner-four .shape-dot.shape-dot-four span {
  width: 12px;
  height: 12px;
  background-color: #ffba00;
}
.banner-four .hero-content {
  padding-right: 40px;
}
.banner-four .hero-content span.tag-line {
  margin-bottom: 20px;
}
.banner-four .hero-content h1 {
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 20px;
  /* padding-right: 10%; */
}
@media (max-width: 1199px) {
  .banner-four .hero-content h1 {
    padding-right: 0;
    font-size: 52px;
    line-height: 65px;
  }
}
@media (max-width: 450px) {
  .banner-four .hero-content h1 {
    font-size: 1.8em;
    line-height: 1em;
  }
}
.banner-four .hero-content p {
  margin-bottom: 35px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.banner-four .hero-content .hero-button a:not(:first-child) {
  margin-left: 30px;
}
@media (max-width: 450px) {
  .banner-four .hero-content .hero-button a:not(:first-child) {
    margin-left: 0;
  }
}
.banner-four .hero-content .hero-button .video-popup i {
  background-color: #18a0ca;
  color: #fff;
}
.banner-four .hero-content .hero-button .video-popup span {
  font-family: "Manrope", sans-serif;
  color: #1a1a1a;
  margin-left: 15px;
  font-weight: 700;
}
.banner-four .hero-content .hero-button .video-popup span:hover {
  color: #d69b6b;
}
.banner-four .hero-img-box {
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .banner-four .hero-img-box {
    display: none;
  }
}

.banner-four .hero-img-box .circle-border {
  position: absolute;
  left: 0;
  top: 80px;
  z-index: -1;
}
.banner-four .hero-img-box .circle-border span {
  position: absolute;
  width: 450px;
  height: 450px;
  border: 1px solid rgba(36, 40, 121, 0.2);
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
  animation: fintex-rotate-animate 10s linear infinite;
}
.banner-four .hero-img-box .circle-border span:nth-child(1) {
  top: -20px;
  left: -55px;
}
.banner-four .hero-img-box .circle-border span:nth-child(2) {
  top: 0;
  left: 25px;
  animation-direction: reverse;
}

.banner-five {
  padding: 70px 0 90px;
  overflow: hidden;
}
.banner-five .pattern-shape {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 120px;
  z-index: -1;
}
@media (max-width: 767px) {
  .banner-five .pattern-shape {
    left: 0;
  }
}
.banner-five .hero-content span.tag-line {
  margin-bottom: 20px;
}
.banner-five .hero-content h1 {
  font-size: 75px;
  line-height: 85px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .banner-five .hero-content h1 {
    padding-right: 0;
    font-size: 52px;
    line-height: 65px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-five .hero-content h1 {
    padding-right: 20%;
  }
}
@media (max-width: 450px) {
  .banner-five .hero-content h1 {
    font-size: 42px;
    line-height: 55px;
  }
}
.banner-five .hero-content p {
  font-weight: 500;
  margin-bottom: 35px;
  font-family: "DM Sans", sans-serif;
}
.banner-five .hero-content .hero-button a:not(:first-child) {
  margin-left: 30px;
}
.banner-five .hero-content .hero-button .video-popup i {
  background-color: #48bb0f;
  color: #fff;
}
.banner-five .hero-content .hero-button .video-popup span {
  font-weight: 700;
  color: #1a1a1a;
  font-family: "Manrope", sans-serif;
  margin-left: 15px;
}
@media (max-width: 1199px) {
  .banner-five .hero-img-box {
    display: none;
  }
}
.banner-five .hero-img-box img {
  max-width: 750px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-five .hero-img-box img {
    max-width: 550px;
  }
}
.banner-five .hero-img-box .shape {
  position: absolute;
  z-index: -1;
}
.banner-five .hero-img-box .shape.square-shape {
  top: 54px;
  right: -175px;
  width: 475px;
  height: 620px;
  background-color: rgba(0, 127, 244, 0.149);
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-five .hero-img-box .shape.square-shape {
    right: -50px;
  }
}
.banner-five .hero-img-box .shape.border-circle {
  bottom: 90px;
  left: -50px;
  width: 750px;
  height: 750px;
  border-radius: 50%;
  border: 1px solid rgba(6, 8, 21, 0.102);
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-five .hero-img-box .shape.border-circle {
    width: 610px;
    height: 610px;
  }
}
.banner-five .hero-img-box .shape.dot-shape1 {
  top: 180px;
  left: -15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #48bb0f;
}
.banner-five .hero-img-box .shape.dot-shape2 {
  bottom: 220px;
  left: -25px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00f0ff;
}
.banner-five .hero-img-box .shape.dot-shape3 {
  top: 20px;
  right: -125px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff0090;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-five .hero-img-box .shape.dot-shape3 {
    right: -50px;
  }
}
.banner-five .hero-img-box .shape.dot-shape4 {
  right: -110px;
  bottom: 110px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffa200;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .banner-five .hero-img-box .shape.dot-shape4 {
    right: -40px;
  }
}
.banner-five .hero-img-box .features-fancy-box {
  margin-top: -180px;
}

@-webkit-keyframes fintex-rotate-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes fintex-rotate-animate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.page-banner {
  padding: 100px 0 180px;
}
.page-banner:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.9);
  mix-blend-mode: multiply;
  z-index: -1;
}
.page-banner .page-banner-content h1.page-title {
  color: #fff;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .page-banner .page-banner-content h1.page-title {
    font-size: 36px;
    line-height: 45px;
  }
}
.page-banner .page-banner-content .breadcrumb-link::before {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  color: #db812e;
  margin-right: 15px;
}
.page-banner .page-banner-content .breadcrumb-link li {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
}
.page-banner .page-banner-content .breadcrumb-link li:not(:last-child):after {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  vertical-align: middle;
  margin-left: 23px;
  margin-right: 19px;
}
.page-banner .page-banner-content .breadcrumb-link li.active {
  color: #db812e;
  border-bottom: 1px solid #db812e;
}

/* provider banner */
.provider-banner {
  padding: 100px 0 180px;
}
.provider-banner:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.9);
  mix-blend-mode: multiply;
  z-index: -1;
}
.provider-banner .page-banner-content h1.page-title {
  color: #fff;
  padding-top: 100px;
}
@media (max-width: 767px) {
  .provider-banner {
    padding: 0px 0 80px;
  }
  .provider-banner .provider-banner-content h1.page-title {
    font-size: 36px;
    line-height: 45px;
  }
}
.provider-banner .provider-banner-content .breadcrumb-link::before {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  color: #db812e;
  margin-right: 15px;
}
.provider-banner .provider-banner-content .breadcrumb-link li {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
}
.provider-banner .provider-banner-content .breadcrumb-link li:not(:last-child):after {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.6);
  vertical-align: middle;
  margin-left: 23px;
  margin-right: 19px;
}
.provider-banner .provider-banner-content .breadcrumb-link li.active {
  color: #db812e;
  border-bottom: 1px solid #db812e;
}

/*---==================
    04. About css 
=================----*/
/* About Section */
.about-section-one {
  position: relative;
}
.about-section-one .shape {
  position: absolute;
}
.about-section-one .shape.shape-one {
  top: 0;
  right: 0;
}
.about-section-one .quote-box-one {
  position: relative;
  z-index: 2;
  margin-top: -185px;
}
@media (max-width: 991px) {
  .about-section-one .quote-box-one {
    margin-top: 0;
    max-width: 500px;
  }
}

.about-section-five .svg-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.about-section-five .svg-shape svg {
  width: 100%;
  height: 100%;
}
.about-section-five .svg-shape svg path {
  fill: #fcf9f7;
}

/* About Image box */
.about-two_image-box .about-one-img {
  position: relative;
  max-width: 600px;
}
.about-two_image-box .about-one-img .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.3);
}
.about-two_image-box .about-one-img .play-box {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}
.about-two_image-box .about-one-img .play-box .video-popup:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
.about-two_image-box .quote-box-four {
  position: relative;
  margin-top: -145px;
  margin-left: -60px;
}
@media (max-width: 1199px) {
  .about-two_image-box .quote-box-four {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .about-two_image-box .quote-box-four {
    margin-top: -45px;
  }
}

.about-three_image-box .about-one-img {
  margin-top: 70px;
  width: 100%;
}
.about-three_image-box .about-two-img img {
  width: 100%;
}
.about-three_image-box .big-logo {
  position: absolute;
  left: 35%;
  bottom: 40px;
}
.about-three_image-box .big-logo img {
  border-radius: 15px;
}

.about-four_image-box {
  min-height: 630px;
}
@media (max-width: 991px) {
  .about-four_image-box {
    margin-bottom: 50px;
  }
}
.about-four_image-box .about-img-one {
  position: absolute;
  top: 150px;
  left: -90px;
}
@media (max-width: 991px) {
  .about-four_image-box .about-img-one {
    left: 25px;
  }
}
.about-four_image-box .about-img-two {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 150px 150px 0px 0px;
}
.about-four_image-box .about-img-three {
  position: absolute;
  bottom: 0;
  right: 50px;
}
.about-four_image-box .shape {
  position: absolute;
  z-index: -1;
}
.about-four_image-box .shape.fancy-shape-one {
  position: absolute;
  left: -25px;
  top: 30px;
}
.about-four_image-box .shape.fancy-shape-one span {
  width: 540px;
  height: 610px;
  background-color: rgba(36, 40, 121, 0.07);
  border-radius: 32% 68% 37% 63%/39% 36% 64% 61%;
}
@media (max-width: 1199px) {
  .about-four_image-box .shape.fancy-shape-one span {
    width: 340px;
    height: 410px;
  }
}
.about-four_image-box .shape.shape-one {
  left: 0;
  bottom: 0;
  width: 90%;
}

.about-five_image-box {
  position: relative;
}
.about-five_image-box .about-img-one {
  margin-top: 70px;
}
@media (max-width: 767px) {
  .about-five_image-box .about-img-one {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .about-five_image-box img {
    width: 100%;
  }
}
.about-five_image-box .big-logo {
  position: absolute;
  bottom: 50px;
  right: 130px;
}

.about-six_image-box .shape {
  position: absolute;
  z-index: -1;
}
.about-six_image-box .shape.shape-one {
  bottom: 0;
  left: 50px;
}
.about-six_image-box .shape.shape-one span {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #007eff;
  -webkit-filter: blur(100px);
          filter: blur(100px);
}
.about-six_image-box .about-img-two {
  position: absolute;
  right: 70px;
  top: 100px;
}

.about-seven_image-box {
  min-height: 450px;
}
.about-seven_image-box .about-img-two {
  position: absolute;
  right: 20px;
  bottom: 45px;
}
.about-seven_image-box .shape {
  position: absolute;
  z-index: -1;
}
.about-seven_image-box .shape.shape-border1 {
  top: -20px;
  left: 10px;
  width: 455px;
  height: 455px;
  border-radius: 50%;
  border: 1px solid rgba(36, 40, 121, 0.2);
}
@media (max-width: 767px) {
  .about-seven_image-box .shape.shape-border1 {
    width: 315px;
    height: 315px;
  }
}
.about-seven_image-box .shape.shape-border2 {
  bottom: 0;
  left: -80px;
  width: 435px;
  height: 435px;
  border-radius: 50%;
  border: 75px solid #109b3e;
}
@media (max-width: 991px) {
  .about-seven_image-box .shape.shape-border2 {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .about-seven_image-box .shape.shape-border2 {
    width: 315px;
    height: 315px;
    border: 55px solid #84ff00;
    left: 0;
    bottom: 130px;
  }
}
.about-seven_image-box .shape.dot-shape1, .about-seven_image-box .shape.dot-shape2, .about-seven_image-box .shape.dot-shape3 {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.about-seven_image-box .shape.dot-shape1 {
  top: 0;
  left: 30px;
  background-color: #ffae00;
}
.about-seven_image-box .shape.dot-shape2 {
  top: 30px;
  right: 25px;
  background-color: #2bdc1f;
}
.about-seven_image-box .shape.dot-shape3 {
  left: -30px;
  bottom: 25px;
  background-color: #df8e0a;
  -webkit-animation: fintex-rotate-animate 2s linear infinite;
          animation: fintex-rotate-animate 2s linear infinite;
}
.about-seven_image-box .shape.dot-shape4 {
  right: -10px;
  bottom: 40%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #0fc10c;
}

/* About Content box */
.about-two_content-box > p {
  margin-bottom: 20px;
}
.about-two_content-box ul.check-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 40px;
}
.about-two_content-box ul.check-style-two li {
  width: 50%;
}
.about-two_content-box .avatar-box .thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
  max-width: 80px;
  margin-right: 25px;
}
.about-two_content-box .avatar-box .text img {
  margin-bottom: 7px;
}

.tab-content-box .nav-tabs {
  border-bottom: none;
}
.tab-content-box .nav-tabs .nav-item:not(:last-child) {
  margin-right: 10px;
}
.tab-content-box .nav-tabs .nav-link {
  padding: 9px 30px;
  border: 1px solid rgba(26, 26, 26, 0.102);
  border-radius: 5px;
  font-weight: 800;
  color: #1a1a1a;
}
.tab-content-box .nav-tabs .nav-link.active {
  background-color: #fff;
  border-color: transparent;
  color: #db812e;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.15);
          box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.15);
}
.tab-content-box .content-box > p {
  margin-bottom: 20px;
}
.tab-content-box .check-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.tab-content-box .check-style-two li {
  width: 50%;
}

/* About Box item */
.about-box-item {
  padding: 30px 35px;
  background-color: #fff;
}
.about-box-item .icon {
  margin-bottom: 10px;
}
.about-box-item .icon i {
  font-size: 55px;
}
.about-box-item .text h5.title {
  font-weight: 700;
}

/*---==================
    05. Service css 
=================----*/
/* Service Slider One */
.service-slider-one {
  margin-left: -15px;
  margin-right: -15px;
}
.service-slider-one .slick-list {
  padding-bottom: 30px;
}
.service-slider-one .service-item {
  padding-left: 15px;
  padding-right: 15px;
}

/* Service Item*/
.service-item:hover .text {
  visibility: hidden;
  opacity: 0;
}
.service-item:hover .hover-text {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
}
.service-item:hover .hover-text .icon-btn {
  color: #1a1a1a;
}
.service-item .img-holder img {
  width: 100%;
  border-radius: 20px;
}
.service-item .service-content {
  position: relative;
  max-width: 260px;
  margin-left: auto;
  margin-right: 35px;
  margin-top: -50px;
  border-radius: 5px;
}
.service-item .text {
  background-color: #fff;
  padding: 20px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 20px;
}
.service-item .text i {
  font-size: 45px;
  color: #db812e;
  margin-right: 20px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45px;
          flex: 0 0 45px;
  max-width: 45px;
}
.service-item .text h3 {
  font-size: 20px;
  line-height: 25px;
}
.service-item .hover-text {
  position: absolute;
  bottom: 0;
  border-radius: 20px;
  padding: 10px 10px 23px;
  background-color: #db812e;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.service-item .hover-text .title-icon {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.302);
}
.service-item .hover-text .title-icon i {
  font-size: 45px;
  color: #fff;
  margin-right: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45px;
  flex: 0 0 45px;
  max-width: 45px;
}
.service-item .hover-text .title-icon h3 {
  font-size: 20px;
  line-height: 25px;
}
.service-item .hover-text .title-icon h3:hover {
  color: #1a1a1a;
}
.service-item .hover-text .icon-btn {
  position: absolute;
  bottom: -20px;
  right: 20px;
  width: 100px;
  height: 40px;
  border-radius: 40px;
  background-color: #fff;
  color: #db812e;
  padding:0 3px
}

/* Service Item Two*/
.service-item-two {
  padding: 10px;
  text-align: center;
}
.service-item-two .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: rgba(249, 77, 28, 0.05);
  font-size: 55px;
  color: #db812e;
  margin-bottom: 25px;
}
.service-item-two .text h3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 6px;
}
.service-item-two .text h3:hover {
  color: #db812e;
}
.service-item-two .text p {
  margin-bottom: 10px;
  color:#000
}

.service-item-three {
  padding: 0 38px 40px;
  border-radius: 7px;
  border: 1px solid #8eb850;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-item-three {
    padding: 0 25px 40px;
  }
}
.service-item-three .icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #8eb850;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 45px;
  color: #fff;
  margin-top: -45px;
}
.service-item-three .text {
  padding-top: 30px;
}
.service-item-three .text h3.title {
  color: #fff;
  margin-bottom: 10px;
}
.service-item-three .text p {
  margin-bottom: 15px;
}
.service-item-three .text .btn-link {
  color: #fff;
}

.service-item-four {
  padding: 40px 35px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 20px;
}
.service-item-four:hover {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
.service-item-four .icon {
  margin-bottom: 15px;
}
.service-item-four .icon i {
  font-size: 55px;
  color: #db812e;
}
.service-item-four .text h3.title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
}
.service-item-four .text h3.title:hover {
  color: #db812e;
}
.service-item-four .text p {
  margin-bottom: 17px;
}

.service-one_image-box .service-one-img {
  margin-right: 40px;
}
@media (max-width: 767px) {
  .service-one_image-box .service-one-img {
    margin-right: 0;
  }
}
.service-one_image-box .shape {
  position: absolute;
  z-index: -1;
}
.service-one_image-box .shape.border-circle {
  right: 13%;
  bottom: 0;
  width: 510px;
  height: 510px;
  border-radius: 50%;
  border: 1px solid rgba(6, 8, 21, 0.102);
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-one_image-box .shape.border-circle {
    right: 28%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service-one_image-box .shape.border-circle {
    right: 20%;
  }
}
@media (max-width: 767px) {
  .service-one_image-box .shape.border-circle {
    display: none;
  }
}
.service-one_image-box .dot-shape {
  position: absolute;
  z-index: -1;
}
.service-one_image-box .dot-shape span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.service-one_image-box .dot-shape.dot-shape1 {
  top: 5%;
  left: 8%;
}
.service-one_image-box .dot-shape.dot-shape1 span {
  background-color: #ffc600;
}
.service-one_image-box .dot-shape.dot-shape2 {
  left: 0;
  bottom: 35%;
}
.service-one_image-box .dot-shape.dot-shape2 span {
  background-color: #0dceb5;
}
.service-one_image-box .dot-shape.dot-shape3 {
  top: 5%;
  right: 50px;
}
.service-one_image-box .dot-shape.dot-shape3 span {
  background-color: #39b76e;
}
.service-one_image-box .dot-shape.dot-shape4 {
  right: 30px;
  bottom: 5%;
}
.service-one_image-box .dot-shape.dot-shape4 span {
  background-color: #d69b6b;
}

/* Service Section */
.service-section:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #3f5016;
  mix-blend-mode: multiply;
  z-index: -1;
}

/* Service Section Two*/
.service-section-two {
  position: relative;
  z-index: 1;
  background-color: #222933;
}
.service-section-two:after {
  position: absolute;
  content: "";
  width: 142.7%;
  height: 100%;
  background: #222933;
  top: 0;
  left: -32%;
  z-index: -1;
}
@media (max-width: 1199px) {
  .service-section-two:after {
    display: none;
  }
}

/* Service Section Three*/
.service-section-three .shape {
  position: absolute;
}
.service-section-three .shape.shape-one {
  bottom: 0;
  right: 0;
}

/* Solution Image Box*/
.solution-one_image-box {
  max-width: 575px;
}
.solution-one_image-box .solution-img-one {
  margin-top: 35%;
  margin-left: auto;
}
.solution-one_image-box .solution-img-two {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
@media (max-width: 450px) {
  .solution-one_image-box .solution-img-two {
    position: relative;
    top: auto;
    right: auto;
    margin-top: 40px;
  }
}
.solution-one_image-box .company-card {
  position: absolute;
  top: 70px;
  left: 90px;
}

/* Optimization Image Box*/
.optimization-one_image-box {
  position: relative;
  max-width: 570px;
  margin-left: auto;
}
.optimization-one_image-box .big-logo {
  position: absolute;
  left: -65px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .optimization-one_image-box .big-logo {
    left: 0;
  }
}
@media (max-width: 450px) {
  .optimization-one_image-box .big-logo {
    left: 0;
  }
}

/*---==================
    06. Features css 
=================----*/
.features-section .shape {
  position: absolute;
}
@media (max-width: 767px) {
  .features-section .shape {
    display: none;
  }
}
.features-section .shape.shape-one {
  left: 80px;
  bottom: 140px;
}
@media (max-width: 1199px) {
  .features-section .shape.shape-one {
    bottom: 45%;
  }
}
.features-section .shape.shape-one span {
  width: 650px;
  height: 650px;
  border-radius: 50%;
  border: 130px solid rgba(249, 77, 28, 0.102);
}

.features-wrapper {
  margin-left: -90px;
  margin-right: -90px;
  border-radius: 8px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(97, 142, 188, 0.10196)), color-stop(60%, rgba(39, 57, 76, 0.21)), to(rgba(1, 1, 1, 0.32157)));
  background: linear-gradient(-180deg, rgba(97, 142, 188, 0.10196) 0%, rgba(39, 57, 76, 0.21) 60%, rgba(1, 1, 1, 0.32157) 100%);
  padding: 70px 90px 30px;
  border-radius: 8px;
}
@media (max-width: 1199px) {
  .features-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media (max-width: 991px) {
  .features-wrapper {
    padding: 50px 30px;
  }
}

.features-item {
  padding: 35px 35px 25px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(172, 172, 172, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(172, 172, 172, 0.1);
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.features-item:hover {
  border-color: #db812e;
}
.features-item .icon {
  margin-bottom: 10px;
}
.features-item .icon i {
  font-size: 50px;
  line-height: 1em;
  color: #db812e;
}
.features-item .text h4.title {
  margin-bottom: 10px;
  letter-spacing: -0.03em;
}

.features-item-two {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(24, 28, 34, 0.07);
          box-shadow: 0px 10px 60px 0px rgba(24, 28, 34, 0.07);
  border-radius: 7px;
  padding: 40px;
  gap: 30px;
}
@media (max-width: 450px) {
  .features-item-two {
    padding: 40px 20px;
    gap: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}
.features-item-two:hover .icon {
  background-color: #db812e;
  color: #fff;
}
.features-item-two:hover .icon-btn {
  background-color: #db812e;
  border-color: transparent;
  color: #fff;
}
.features-item-two span.number {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
}
.features-item-two .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
          flex: 0 0 100px;
  max-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: transparent;
  color: #db812e;
  font-size: 50px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 450px) {
  .features-item-two .icon {
    width: 100px;
  }
}
@media (max-width: 1199px) {
  .features-item-two .text {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
.features-item-two .text h3.title {
  letter-spacing: -0.03em;
}
@media (max-width: 450px) {
  .features-item-two .text h3.title {
    margin-bottom: 20px;
  }
}
.features-item-two .icon-btn {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid rgba(24, 28, 34, 0.102);
  font-size: 18px;
  color: #db812e;
}
@media (max-width: 450px) {
  .features-item-two .icon-btn {
    width: 60px;
  }
}

.features-item-three {
  padding: 35px 35px 28px;
  border: 1px solid rgba(26, 26, 26, 0.071);
}
.features-item-three .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  max-width: 40px;
  margin-right: 20px;
}
.features-item-three .icon i {
  color: #db812e;
  font-size: 40px;
}
.features-item-three .text {
  margin-top: -7px;
}
.features-item-three .text h4.title {
  margin-bottom: 10px;
}

.features-item-left-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 450px) {
  .features-item-left-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .features-item-left-box .icon {
    margin-bottom: 20px;
  }
}
.features-item-left-box .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45px;
          flex: 0 0 45px;
  max-width: 45px;
  margin-right: 20px;
}
.features-item-left-box .icon i {
  font-size: 45px;
  color: #db812e;
}
.features-item-left-box .text {
  margin-top: -7px;
}
.features-item-left-box .text h4.title {
  margin-bottom: 10px;
}

.features-item-four {
  padding: 40px 30px 30px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.features-item-four:hover {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
.features-item-four .icon {
  margin-bottom: 10px;
}
.features-item-four .icon i {
  font-size: 55px;
  line-height: 1;
  color: #db812f;
}
.features-item-four .text h4.title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 7px;
}

.features-item-five {
  padding: 40px 35px 30px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
.features-item-five .icon {
  margin-bottom: 18px;
}
.features-item-five .icon i {
  font-size: 55px;
  line-height: 1;
}
.features-item-five .text h5.title {
  margin-bottom: 15px;
}
.features-item-five .text p {
  margin-bottom: 13px;
}

.features-fancy-box {
  background-color: #007eff;
  display: inline-block;
  padding: 35px 40px 30px;
  border-radius: 14px;
}
.features-fancy-box h5 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 30px;
}
.features-fancy-box .fancy-circle {
  position: relative;
  width: 180px;
  height: 180px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 15px;
}
.features-fancy-box .fancy-circle .span1 {
  position: absolute;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-color: transparent #fff #fff;
  -webkit-animation: fintex-rotate-animate 2s linear infinite;
          animation: fintex-rotate-animate 2s linear infinite;
  animation-direction: reverse;
}
.features-fancy-box .fancy-circle .span2 {
  position: absolute;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
}
.features-fancy-box .fancy-circle .span2:after {
  content: "";
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: initial;
  border: 20px solid transparent;
  position: absolute;
  -webkit-animation: rotation 2s linear infinite;
          animation: rotation 2s linear infinite;
  border-bottom-color: #fff;
  top: -10px;
  left: -10px;
  -webkit-animation: fintex-rotate-animate 3s linear infinite;
          animation: fintex-rotate-animate 3s linear infinite;
}
.features-fancy-box .fancy-circle .span3 {
  position: absolute;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
.features-fancy-box ul li {
  color: #fff;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.features-fancy-box ul li i {
  margin-right: 10px;
}

/* Features Image box */
.features-one_img-box {
  position: relative;
  max-width: 570px;
  min-height: 775px;
}
.features-one_img-box .features-img-two {
  position: absolute;
  bottom: 0;
  right: 0;
}
.features-one_img-box .quote-box-two {
  position: absolute;
  bottom: 22%;
  left: 0;
}
.features-one_img-box .big-logo {
  position: absolute;
  top: 150px;
  right: 50px;
}

.features-two_img-box .shape {
  position: absolute;
  z-index: -1;
}
.features-two_img-box .shape.shape-one {
  bottom: 30px;
  left: 50px;
}
@media (max-width: 767px) {
  .features-two_img-box .shape.shape-one {
    left: 0;
  }
}
.features-two_img-box .shape.shape-one span {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #007eff;
  -webkit-filter: blur(100px);
          filter: blur(100px);
}

/*====== Process Section CSS ======*/
.process-image-box {
  min-height: 665px;
}
.process-image-box .process-img-two {
  position: absolute;
  bottom: 0;
  right: 30px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
@media (max-width: 991px) {
  .process-image-box .process-img-two {
    bottom: 50px;
  }
}
.process-image-box .shape {
  position: absolute;
  z-index: -1;
}
.process-image-box .shape.border-circle1 {
  top: -50px;
  left: -30%;
  width: 570px;
  height: 570px;
  border-radius: 50%;
  border: 1px solid rgba(6, 8, 21, 0.102);
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
}
@media (max-width: 767px) {
  .process-image-box .shape.border-circle1 {
    display: none;
  }
}
.process-image-box .shape.border-circle2 {
  left: -25%;
  bottom: -35px;
  width: 410px;
  height: 410px;
  border-radius: 50%;
  border: 80px solid #ffae00;
}
@media (max-width: 991px) {
  .process-image-box .shape.border-circle2 {
    left: 0;
    bottom: 5%;
  }
}
@media (max-width: 767px) {
  .process-image-box .shape.border-circle2 {
    width: 310px;
    height: 310px;
    border: 50px solid #ffae00;
  }
}

.process-left-item {
  background-color: #fff;
  padding: 25px 35px 17px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
@media (max-width: 450px) {
  .process-left-item {
    padding: 25px 25px 17px;
  }
}
.process-left-item .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  font-size: 30px;
  background-color: #007eff;
  color: #fff;
  margin-right: 25px;
}
.process-left-item .text h4.title {
  margin-bottom: 8px;
}
.process-left-item:nth-child(2) .icon {
  background-color: #48bb0f;
}
.process-left-item:nth-child(3) .icon {
  background-color: #0fbbb4;
}

.process-content-box ul li.process-left-item:nth-child(even) {
  margin-left: 20px;
}

.single-progress-bar .progress-title {
  margin-bottom: 25px;
}
.single-progress-bar .progress-title h5 {
  font-size: 20px;
}
.single-progress-bar .progress-title h5 span {
  position: relative;
  float: right;
  background-color: #d69b6b;
  font-size: 18px;
  color: #fff;
  padding: 7px 9px;
  line-height: 1;
}
.single-progress-bar .progress-title h5 span:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #db812e;
}
.single-progress-bar .progress {
  border-radius: 0;
  height: 5px;
  background-color: rgba(251, 88, 80, 0.2);
}
.single-progress-bar .progress .progress-bar {
  background-color: #d69b6b;
}
.single-progress-bar.progress-bar-two .progress-title h5 span {
  background-color: #18a0ca;
}
.single-progress-bar.progress-bar-two .progress-title h5 span:after {
  border-top: 10px solid #18a0ca;
}
.single-progress-bar.progress-bar-two .progress {
  background-color: rgba(24, 160, 202, 0.2);
}
.single-progress-bar.progress-bar-two .progress .progress-bar {
  background-color: #18a0ca;
}

/*======  Quote Box  ======*/
.quote-box-one {
  background-color: #db812e;
  padding: 90px 80px;
  border-radius: 120px 5px 5px 5px;
}
@media (max-width: 767px) {
  .quote-box-one {
    padding: 50px 40px;
  }
}
.quote-box-one h2 {
  font-size: 50px;
  line-height: 1em;
  color: #fff;
  letter-spacing: -0.03em;
}
@media (max-width: 1199px) {
  .quote-box-one h2 {
    font-size: 42px;
    line-height: 55px;
  }
}
@media (max-width: 767px) {
  .quote-box-one h2 {
    font-size: 32px;
    line-height: 42px;
  }
}

.quote-box-two {
  padding: 30px 55px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #db812e;
  max-width: 265px;
}
.quote-box-two h3 {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.03em;
  color: #fff;
}

.quote-box-three {
  background-color: #db812e;
  max-width: 390px;
  padding: 50px;
}
@media (max-width: 767px) {
  .quote-box-three {
    padding: 50px 30px;
  }
}
.quote-box-three h3 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.03em;
  color: #fff;
  margin-bottom: 20px;
}
.quote-box-three .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 65px;
          flex: 0 0 65px;
  max-width: 65px;
  margin-right: 20px;
}
.quote-box-three .author-thumb-title .author-thumb img {
  width: 100%;
  border-radius: 50%;
}
.quote-box-three .author-thumb-title .author-title h4.title {
  color: #fff;
}
.quote-box-three .author-thumb-title .author-title p.position {
  color: #fff;
  font-weight: 700;
}

.quote-box-four {
  background-color: #db812e;
  border-radius: 65px 15px 15px 15px;
  max-width: 385px;
  padding: 55px 40px 50px 55px;
}
@media (max-width: 767px) {
  .quote-box-four {
    padding: 35px 30px 30px 35px;
  }
}
.quote-box-four h3 {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.03em;
}
@media (max-width: 767px) {
  .quote-box-four h3 {
    font-size: 22px;
    line-height: 32px;
  }
}

.quote-box-rotate-90 {
  background-color: #db812e;
  padding: 20px 50px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

/*---==================
    07. Elements css 
=================----*/
/* Content box gap */
.content-box-gap > p {
  margin-bottom: 30px;
}
.content-box-gap ul {
  margin-bottom: 30px;
}

/* Animated Icon Hover */
.animate-hover-icon:hover .icon i {
  -webkit-animation: shake linear 0.3s infinite;
          animation: shake linear 0.3s infinite;
}

/* Company Card*/
.company-card {
  padding: 20px 25px 20px;
  background-color: #fff;
  max-width: 165px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(114, 114, 114, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(114, 114, 114, 0.3);
}
.company-card h5 {
  margin-bottom: 15px;
}

/* Video Popup */
.video-popup i {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #db812e;
}

/* Checkbox style */
.form_checkbox input[type=checkbox] {
  display: none;
}
.form_checkbox input[type=checkbox]:checked + label span:after {
  visibility: visible;
  opacity: 1;
}
.form_checkbox label {
  cursor: pointer;
}
.form_checkbox label span {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  line-height: 1;
}
.form_checkbox label span:before {
  position: absolute;
  top: -2px;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid rgba(26, 26, 26, 0.2);
  margin-right: 10px;
}
.form_checkbox label span:after {
  position: absolute;
  top: 3px;
  left: 5px;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #db812e;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.form_checkbox_two input[type=checkbox] {
  display: none;
}
.form_checkbox_two input[type=checkbox]:checked + label span:after {
  visibility: visible;
  opacity: 1;
}
.form_checkbox_two label {
  cursor: pointer;
}
.form_checkbox_two label span {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  line-height: 1;
  text-align: left;
}
.form_checkbox_two label span:before {
  position: absolute;
  top: -2px;
  left: 0;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 10px;
}
.form_checkbox_two label span:after {
  position: absolute;
  top: 5px;
  left: 7px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: #007eff;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* Check Style  */
.check-style-one li {
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 5px;
}
.check-style-one li:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: #db812e;
  margin-right: 10px;
}

.check-style-two li {
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 5px;
}
.check-style-two li:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #fff;
  margin-right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #db812e;
  font-size: 12px;
}

.check-style-three li {
  font-weight: 500;
  color: #1a1a1a;
  margin-bottom: 5px;
}
.check-style-three li:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #db812e;
  margin-right: 10px;
}

/*====== Skill Section CSS ======*/
.skill-one_content-box .section-title {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
}
.skill-one_content-box .section-title p {
  margin-bottom: 15px;
}

.skill-one_image-box {
  max-width: 530px;
}
.skill-one_image-box .quote-box-rotate-90 {
  position: absolute;
  top: 108px;
  right: -70px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.single-skill-item .chart-circle {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 85px;
          flex: 0 0 85px;
  max-width: 85px;
  height: 85px;
  margin-right: 30px;
}
.single-skill-item .chart-circle h2.number {
  font-size: 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.single-skill-item .chart-circle h2.number span.sign {
  font-size: 16px;
}
.single-skill-item .text h3.title {
  letter-spacing: -0.03em;
}

.skill-img-box .circle-border span {
  position: absolute;
  width: 595px;
  height: 595px;
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  border: 1px solid rgba(36, 40, 121, 0.2);
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-img-box .circle-border span {
    width: 495px;
    height: 495px;
  }
}
@media (max-width: 767px) {
  .skill-img-box .circle-border span {
    display: none;
  }
}
.skill-img-box .circle-border span:nth-child(1) {
  right: -70px;
  top: 0;
}
@media (max-width: 1199px) {
  .skill-img-box .circle-border span:nth-child(1) {
    right: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-img-box .circle-border span:nth-child(1) {
    right: -40px;
  }
}
@media (max-width: 767px) {
  .skill-img-box .circle-border span:nth-child(1) {
    right: 0;
  }
}
.skill-img-box .circle-border span:nth-child(2) {
  top: 40px;
  right: -30px;
  animation-direction: reverse;
}
@media (max-width: 1199px) {
  .skill-img-box .circle-border span:nth-child(2) {
    right: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .skill-img-box .circle-border span:nth-child(2) {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .skill-img-box .circle-border span:nth-child(2) {
    right: 50px;
  }
}

/*====== Fact Section CSS ======*/
.mb-minus-150 {
  margin-bottom: -150px;
}

.fact-section-two {
  background-color: #181c22;
  padding-left: 70px;
}
@media (max-width: 1199px) {
  .fact-section-two {
    padding-left: 0;
  }
}

.fact-section-three:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 250px;
  background: url(../../public/assets/images/bg/counter-bg-home-three.png) no-repeat;
  z-index: -1;
}

.fact-wrapper-one {
  background-color: #242879;
  margin-left: -90px;
  margin-right: -90px;
  padding-left: 90px;
  padding-right: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.fact-wrapper-one:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f05024;
  -webkit-transform: rotateZ(357deg);
          transform: rotateZ(357deg);
  z-index: -1;
}
@media (max-width: 767px) {
  .fact-wrapper-one:before {
    display: none;
  }
}
.fact-wrapper-one .svg-shape {
  position: absolute;
  z-index: -1;
}
.fact-wrapper-one .svg-shape.svg-shape-one {
  top: 0;
  right: 0;
  width: 50%;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .fact-wrapper-one {
    margin-left: -50px;
    margin-right: -50px;
  }
}
@media (max-width: 1199px) {
  .fact-wrapper-one {
    margin-left: 0;
    margin-right: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fact-wrapper-one {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .fact-wrapper-one .counter-item-white {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .fact-wrapper-one .counter-item-white:not(:last-child) {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .fact-wrapper-one {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
  }
  .fact-wrapper-one .counter-item-white {
    width: 100%;
  }
  .fact-wrapper-one .counter-item-white:not(:last-child) {
    margin-bottom: 40px;
  }
}

.fact-img_one-box {
  max-width: 650px;
}
.fact-img_one-box .quote-box-three {
  position: absolute;
  top: -50px;
  left: 10px;
  border-radius: 50px;
  font-style: italic;
}
@media (max-width: 1199px) {
  .fact-img_one-box .quote-box-three {
    left: 0;
  }
}
@media (max-width: 767px) {
  .fact-img_one-box .quote-box-three {
    position: relative;
    top: auto;
    margin-bottom: 50px;
  }
}

.fact-one_content-box > p {
  margin-bottom: 40px;
  font-size: 18px;
}

.single-counter-item {
  max-width: 470px;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-counter-item:hover {
  border-color: #db812e;
}
.single-counter-item .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 45px;
          flex: 0 0 45px;
  max-width: 45px;
  height: 45px;
  border: 2px solid rgba(26, 26, 26, 0.102);
  border-radius: 50%;
  color: #db812e;
  margin-right: 25px;
}
@media (max-width: 450px) {
  .single-counter-item .text {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.single-counter-item .text span.number {
  font-size: 30px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  max-width: 180px;
  font-family: "Manrope", sans-serif;
  color: #db812e;
}
.single-counter-item .text h5 {
  letter-spacing: -0.03em;
}
@media (max-width: 450px) {
  .single-counter-item .text h5 {
    margin-top: 10px;
  }
}

.single-counter-item-two .text {
  padding: 0 10px;
  gap: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-counter-item-two .text {
    padding: 0;
  }
}
.single-counter-item-two .text h2.number {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 120px;
          flex: 0 0 120px;
  max-width: 120px;
  font-size: 50px;
  letter-spacing: -0.03em;
  color: #db812e;
}
.single-counter-item-two .text h5 {
  letter-spacing: -0.03em;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}

.single-counter-item-three {
  padding: 45px 30px 50px;
  border-radius: 14px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(97, 142, 188, 0.05882)), color-stop(58%, rgba(41, 60, 80, 0.1)), to(rgba(1, 1, 1, 0.14902)));
  background: linear-gradient(-180deg, rgba(97, 142, 188, 0.05882) 0%, rgba(41, 60, 80, 0.1) 58%, rgba(1, 1, 1, 0.14902) 100%);
}
.single-counter-item-three .text h2.number {
  color: #007eff;
  margin-bottom: 10px;
}
.single-counter-item-three .text h5 {
  font-weight: 600;
}

.counter-item-white .icon {
  margin-bottom: 20px;
}
.counter-item-white .icon i {
  font-size: 60px;
  line-height: 1;
  color: #fff;
}
.counter-item-white .text p, .counter-item-white .text h2.number {
  color: #fff;
}
.counter-item-white .text h2.number {
  margin-bottom: 10px;
}

/*====== Pricing Section CSS ======*/
.pricing-item-one {
  position: relative;
  padding: 20px;
  border: 1px solid rgba(24, 28, 34, 0.071);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.pricing-item-one:hover {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(204, 204, 204, 0.5);
          box-shadow: 0px 10px 60px 0px rgba(204, 204, 204, 0.5);
  border-color: transparent;
}
.pricing-item-one span.save {
  position: absolute;
  top: -30px;
  left: 50px;
  background-color: #db812e;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  border-radius: 13px;
  padding:15px 50px;
  line-height: 16px;
}
.pricing-item-one .pricing-header {
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}
.pricing-item-one .pricing-header:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 10px;
  background: url(../../public/assets/images/line.png) no-repeat center;
  background-size: contain;
  z-index: -1;
}
.pricing-item-one .pricing-header span.plan {
  font-size: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 15px;
}
.pricing-item-one .pricing-header h2.price {
  letter-spacing: -0.03em;
}
.pricing-item-one .pricing-header h2.price span {
  font-size: 24px;
  line-height: 50px;
  vertical-align: baseline;
}
.pricing-item-one .pricing-body ul.pricing-list {
  margin-bottom: 27px;
}
@media (min-width:766px) {
  .pricing-item-one .pricing-body ul.pricing-list {
    min-height: 60rem;
    
  }
}
.pricing-item-one .pricing-body ul.pricing-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
}
.pricing-item-one .pricing-body ul.pricing-list li.uncheck {
  color: rgba(116, 120, 124, 0.3);
}
.pricing-item-one .pricing-body ul.pricing-list li.uncheck::before {
  color: rgba(116, 120, 124, 0.3);
}
/* .pricing-item-one .pricing-body ul.pricing-list li:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  color: #db812e;
  margin-right: 10px;
  font-size: 18px;
} */
.pricing-item-one .pricing-body .main-btn {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  min-width: 200px;
}

.pricing-item-two {
  position: relative;
  padding: 30px 40px 40px;
  background-color: #fafafa;
  border: 1px solid rgba(26, 26, 26, 0.15);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.pricing-item-two:hover {
  background-color: #fff;
  border-color: transparent;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(204, 204, 204, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(204, 204, 204, 0.2);
}
.pricing-item-two span.save {
  position: absolute;
  top: -13px;
  left: 15px;
  background-color: #db812e;
  color: #fff;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  border-radius: 13px;
  padding: 5px 15px;
  line-height: 16px;
}
.pricing-item-two .pricing-header {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}
.pricing-item-two .pricing-header:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 10px;
  background: url(../../public/assets/images/line-2.png) no-repeat center;
  background-size: contain;
  z-index: -1;
}
.pricing-item-two .pricing-header span.plan {
  font-size: 24px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 15px;
}
.pricing-item-two .pricing-header h2.price {
  letter-spacing: -0.03em;
}
.pricing-item-two .pricing-header h2.price span {
  font-size: 24px;
  line-height: 50px;
  vertical-align: baseline;
}
.pricing-item-two .pricing-header h2.price span.duration {
  font-weight: 400;
  color: #74787c;
  font-size: 18px;
}
.pricing-item-two .pricing-body .pricing-list li.check {
  display: block;
  font-size: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  margin-bottom: 22px;
}
.pricing-item-two .pricing-body .pricing-list li.check:after {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-weight: 600;
  font-family: "Font Awesome 5 Pro";
  color: #db812e;
  float: right;
}
.pricing-item-two .pricing-body .main-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/*====== History Section CSS ======*/
.history-timeline {
  position: relative;
  z-index: 1;
}
.history-timeline:after {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: url(../../public/assets/images/timeline.png) no-repeat center;
  background-size: contain;
  z-index: -1;
}

.single-history-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.single-history-item .history-img, .single-history-item .history-content {
  width: 50%;
}
@media (max-width: 450px) {
  .single-history-item .history-img, .single-history-item .history-content {
    width: 100%;
  }
}
.single-history-item .history-img img {
  width: 100%;
}
.single-history-item .history-content {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.15);
          box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.15);
  padding: 40px 35px 35px;
  margin-top: 30px;
  margin-left: -30px;
}
@media (max-width: 1199px) {
  .single-history-item .history-content {
    padding: 40px 20px 35px;
  }
}
@media (max-width: 450px) {
  .single-history-item .history-content {
    margin-left: 0;
    padding: 40px 30px 35px;
  }
}
.single-history-item .history-content h4.title {
  margin-bottom: 10px;
}
@media (max-width: 1199px) {
  .single-history-item .history-content h4.title {
    font-size: 18px;
  }
}
.single-history-item .history-content h4.title span.ribbon {
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  background-color: #db812e;
  color: #fff;
  padding: 5px 20px 5px 13px;
  line-height: 20px;
  margin-right: 15px;
}
.single-history-item .history-content h4.title span.ribbon:after {
  position: absolute;
  right: 0;
  content: "";
  top: 0;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 15px solid transparent;
}
.single-history-item:nth-child(even) {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.single-history-item:nth-child(even) .history-content {
  margin-left: 0;
  margin-right: -30px;
}
@media (max-width: 450px) {
  .single-history-item:nth-child(even) .history-content {
    margin-right: 0;
  }
}

/*====== Categories Section CSS ======*/
.categories-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.categories-nav a {
  text-align: center;
  padding: 15px 30px;
  border: 1px solid rgba(26, 26, 26, 0.102);
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  max-width: 100%;
  color: #1a1a1a;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  border-radius: 7px;
}
.categories-nav a i {
  margin-right: 7px;
  color: #db812e;
}
.categories-nav a:hover {
  background-color: #db812e;
  border-color: transparent;
  color: #fff;
}
.categories-nav a:hover i {
  color: #fff;
}

/*====== Job Details CSS ======*/
.jobs-heading h3.title {
  margin-bottom: 10px;
}

.single-job-item {
  padding: 30px;
  border: 1px solid rgba(26, 26, 26, 0.071);
  border-radius: 7px;
}
@media (max-width: 767px) {
  .single-job-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.single-job-item .job-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 55px;
          flex: 0 0 55px;
  max-width: 55px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .single-job-item .job-thumb {
    margin-bottom: 20px;
  }
}
.single-job-item .job-main-content {
  margin-top: -6px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
@media (max-width: 1199px) {
  .single-job-item .job-main-content {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.single-job-item .job-main-content .job-content h3.title {
  font-size: 18px;
  letter-spacing: -0.03em;
  margin-bottom: 7px;
}
.single-job-item .job-main-content .job-content h3.title a:hover {
  color: #db812e;
  text-decoration: underline;
}
.single-job-item .job-main-content .job-content h3.title span.status {
  padding: 5px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  font-family: "Manrope", sans-serif;
  border-radius: 5px;
  color: #fff;
  background-color: #fafafa;
  margin-left: 10px;
}
.single-job-item .job-main-content .job-content h3.title span.status.full {
  background-color: #db812e;
}
.single-job-item .job-main-content .job-content h3.title span.status.part {
  background-color: #00c3ff;
}
.single-job-item .job-main-content .job-content h3.title span.status.features {
  background-color: #002aff;
}
.single-job-item .job-main-content .job-content .job-meta span {
  font-size: 14px;
}
.single-job-item .job-main-content .job-content .job-meta span:not(:last-child) {
  margin-right: 15px;
}
.single-job-item .job-main-content .job-content .job-meta span i {
  margin-right: 7px;
}
@media (max-width: 991px) {
  .single-job-item .job-main-content .apply-button {
    margin-top: 10px;
  }
}
.single-job-item .job-main-content .apply-button p {
  margin-bottom: 5px;
}
.single-job-item .job-main-content .apply-button p span.date {
  color: #db812e;
}
.single-job-item .job-main-content .wishlist-button a {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid rgba(26, 26, 26, 0.071);
  color: #db812e;
}
.single-job-item .job-main-content .wishlist-button a:hover {
  background-color: #db812e;
  border-color: transparent;
  color: #fff;
}

.job-details-wrapper .description-box h4.title {
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
  margin-bottom: 25px;
}
.job-details-wrapper .description-box h4.title:after {
  position: absolute;
  content: "";
  bottom: -0.5px;
  left: 0;
  width: 200px;
  height: 2px;
  background-color: #db812e;
}
.job-details-wrapper .description-box > p {
  margin-bottom: 15px;
}
.job-details-wrapper .description-box ul.social-link li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(26, 26, 26, 0.102);
  color: rgba(26, 26, 26, 0.502);
  margin-right: 5px;
}
.job-details-wrapper .description-box ul.social-link li a:hover {
  background-color: #db812e;
  color: #fff;
  border-color: transparent;
}
.job-details-wrapper .description-box.gallery-box .gallery-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.job-details-wrapper .description-box.gallery-box .gallery-slider .single-gallery-img {
  padding-left: 15px;
  padding-right: 15px;
}
.job-details-wrapper .description-box.gallery-box .gallery-slider .single-gallery-img img {
  width: 100%;
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video {
  position: relative;
  overflow: hidden;
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.3);
  mix-blend-mode: multiply;
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video img {
  width: 100%;
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video .play-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video .play-content .video-popup i {
  width: 70px;
  height: 70px;
}
.job-details-wrapper .description-box.gallery-box .video-slider .single-video .play-content .video-popup i:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

.jobs-sidebar .job-widget {
  background-color: #fafafa;
  padding: 40px;
}
.jobs-sidebar .job-widget h4.title {
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
  margin-bottom: 25px;
}
.jobs-sidebar .job-widget h4.title:after {
  position: absolute;
  content: "";
  bottom: -0.5px;
  left: 0;
  width: 65px;
  height: 2px;
  background-color: #db812e;
}
.jobs-sidebar .job-widget.job-info-widget .job-info-box {
  margin-bottom: 15px;
}
.jobs-sidebar .job-widget.job-info-widget .job-info-box .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20px;
          flex: 0 0 20px;
  max-width: 20px;
  margin-right: 15px;
}
.jobs-sidebar .job-widget.job-info-widget .job-info-box .icon i {
  font-size: 18px;
  color: #db812e;
}
.jobs-sidebar .job-widget.job-skill-widget a {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 7px;
  margin-right: 3px;
  color: #1a1a1a;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(124, 124, 124, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(124, 124, 124, 0.2);
}
.jobs-sidebar .job-widget.job-location-widget .map-box iframe {
  height: 350px;
}

/*====== Partners Section CSS ======*/
.partners-area .partner-arrows {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.partners-area .partner-arrows:after {
  position: absolute;
  bottom: 20px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(24, 28, 34, 0.071);
}
.partners-area .partner-arrows .slick-arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(159, 159, 159, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(159, 159, 159, 0.3);
  background-color: #fff;
  color: #0dceb5;
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.partners-area .partner-arrows .slick-arrow:hover {
  background-color: #0dceb5;
  color: #fff;
}

.partner-wrapper-one {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(36, 40, 121, 0.07);
          box-shadow: 0px 10px 60px 0px rgba(36, 40, 121, 0.07);
  margin-left: -90px;
  margin-right: -90px;
  padding-left: 90px;
  padding-right: 90px;
}
@media (max-width: 1199px) {
  .partner-wrapper-one {
    margin-left: 0;
    margin-right: 0;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.partner-slider-two,
.partner-slider-one {
  margin-left: -40px;
  margin-right: -40px;
}
@media (max-width: 991px) {
  .partner-slider-two,
.partner-slider-one {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.partner-slider-two .partner-item,
.partner-slider-one .partner-item {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 991px) {
  .partner-slider-two .partner-item,
.partner-slider-one .partner-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.partner-item {
  text-align: center;
  cursor: pointer;
}
.partner-item:hover .partner-img img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

/*====== Video Section CSS ======*/
.video-wrapper-one {
  margin-left: -85px;
  margin-right: -85px;
  padding-left: 85px;
  padding-right: 85px;
  margin-top: -37%;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .video-wrapper-one {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-wrapper-one {
    margin-top: -35%;
  }
}
@media (max-width: 991px) {
  .video-wrapper-one {
    margin-top: 50px;
  }
}
.video-wrapper-one .shape {
  position: absolute;
}
.video-wrapper-one .shape.shape-one {
  bottom: 0;
  right: 0;
}
.video-wrapper-one .shape.shape-one path {
  fill: #db812e;
  mix-blend-mode: multiply;
}
.video-wrapper-one:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.1);
  mix-blend-mode: multiply;
  z-index: -1;
}
.video-wrapper-one .play-content .video-popup i {
  width: 90px;
  height: 90px;
}
.video-wrapper-one .play-content .video-popup i:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

/*====== Utility Section CSS ======*/
.utility-section {
  position: relative;
  z-index: 1;
  margin-bottom: -160px;
}
.utility-section:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  background-color: #181c22;
  z-index: -1;
}
@media (max-width: 1199px) {
  .utility-section:after {
    width: 100%;
  }
}

/*====== Benefits Section CSS ======*/
.benefits-content-box > p {
  margin-bottom: 25px;
}
.benefits-content-box ul.check-style-two li {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.benefits-image-box {
  min-height: 550px;
}
.benefits-image-box img {
  position: absolute;
  border-radius: 15px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.4);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.4);
}
.benefits-image-box .benefit-img-one {
  left: -15px;
  top: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .benefits-image-box .benefit-img-one {
    left: 50px;
  }
}
.benefits-image-box .benefit-img-two {
  top: 0;
  right: 25px;
}
.benefits-image-box .benefit-img-three {
  right: -50px;
  bottom: 0;
}
@media (max-width: 1199px) {
  .benefits-image-box .benefit-img-three {
    right: 0;
  }
}
.benefits-image-box .shape {
  position: absolute;
  z-index: -1;
}
.benefits-image-box .shape.circle-shape {
  top: 40px;
  left: 70px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  background-color: #fcf9f7;
  z-index: -1;
}
@media (max-width: 1199px) {
  .benefits-image-box .shape.circle-shape {
    left: 0;
  }
}
@media (max-width: 767px) {
  .benefits-image-box .shape.circle-shape {
    width: 310px;
    height: 310px;
  }
}
.benefits-image-box .shape.border-circle {
  top: 30px;
  left: 35px;
  width: 510px;
  height: 510px;
  border-radius: 50%;
  border: 1px solid rgba(6, 8, 21, 0.102);
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
}
@media (max-width: 1199px) {
  .benefits-image-box .shape.border-circle {
    width: 410px;
    height: 410px;
    left: 0;
  }
}
@media (max-width: 767px) {
  .benefits-image-box .shape.border-circle {
    display: none;
  }
}
.benefits-image-box .dot-shape {
  position: absolute;
  z-index: -1;
}
.benefits-image-box .dot-shape span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
.benefits-image-box .dot-shape.dot-shape1 {
  left: 15%;
  top: 5%;
}
.benefits-image-box .dot-shape.dot-shape1 span {
  background-color: #ffae00;
}
.benefits-image-box .dot-shape.dot-shape2 {
  top: -50px;
  left: 55%;
}
.benefits-image-box .dot-shape.dot-shape2 span {
  background-color: #007ff4;
}
.benefits-image-box .dot-shape.dot-shape3 {
  left: 30%;
  bottom: 45px;
}
.benefits-image-box .dot-shape.dot-shape3 span {
  background-color: #00d8ff;
}
.benefits-image-box .dot-shape.dot-shape4 {
  right: 15%;
  bottom: -30px;
}
.benefits-image-box .dot-shape.dot-shape4 span {
  background-color: #d69b6b;
}
.benefits-image-box .dot-shape.dot-shape5 {
  right: 0;
  top: 20%;
}
.benefits-image-box .dot-shape.dot-shape5 span {
  background-color: #0fbbb4;
}

/*====== CTA Section CSS ======*/
.cta-section:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(249, 77, 28, 0.83);
  z-index: -1;
}

.cta-section-two {
  background-color: #181c22;
  padding-left: 90px;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .cta-section-two {
    padding-left: 0;
  }
}
.cta-section-two:before {
  position: absolute;
  content: "";
  width: 30%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../../public/assets/images/gallery/map.png) no-repeat center;
  background-size: contain;
  z-index: -1;
}
.cta-section-two .section-title h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
}
.cta-section-two .section-title h2 span {
  color: #db812e;
  font-weight: 800;
}

/*====== Newsletter Section CSS ======*/
.newsletter-section .section-title h2 {
  font-size: 32px;
  line-height: 45px;
}
.newsletter-section .newsletter-from form .form_control {
  padding: 0 60px;
  background-color: transparent;
  border-bottom: 2px solid rgba(250, 250, 250, 0.1);
  color: rgba(250, 250, 250, 0.7);
  margin-bottom: 7px;
}
.newsletter-section .newsletter-from form .form_control::-webkit-input-placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.newsletter-section .newsletter-from form .form_control::-moz-placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.newsletter-section .newsletter-from form .form_control:-ms-input-placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.newsletter-section .newsletter-from form .form_control::-ms-input-placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.newsletter-section .newsletter-from form .form_control::placeholder {
  color: rgba(250, 250, 250, 0.7);
}
.newsletter-section .newsletter-from form i {
  position: absolute;
  top: 21px;
  left: 30px;
  color: #db812e;
}
@media (max-width: 991px) {
  .newsletter-section .newsletter-from form .form_checkbox {
    margin-bottom: 20px;
  }
}
.newsletter-section .newsletter-from form .form_checkbox label {
  padding-left: 30px;
}
.newsletter-section .newsletter-from form .form_checkbox label span {
  position: relative;
}
.newsletter-section .newsletter-from form .form_checkbox label span a {
  color: #fff;
  text-decoration: underline;
  margin-left: 5px;
}
.newsletter-section .newsletter-from form .form_checkbox label span a:hover {
  color: #db812e;
}
.newsletter-section .newsletter-from form .form_checkbox label span:after {
  top: 5.5px;
  left: 4px;
  width: 5px;
  height: 5px;
  background-color: #fff;
}
.newsletter-section .newsletter-from form .form_checkbox label span:before {
  top: 2px;
  width: 13px;
  height: 13px;
  border: 1px solid rgba(250, 250, 250, 0.4);
}

.newsletter-wrapper-one {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(97, 142, 188, 0.10196)), color-stop(60%, rgba(39, 57, 76, 0.21)), to(rgba(1, 1, 1, 0.32157)));
  background: linear-gradient(-180deg, rgba(97, 142, 188, 0.10196) 0%, rgba(39, 57, 76, 0.21) 60%, rgba(1, 1, 1, 0.32157) 100%);
  border-radius: 7px;
  padding-left: 30px;
  padding-right: 30px;
}

.newsletter-wrapper-two {
  background-color: #f7f6f9;
  position: relative;
  margin-left: -90px;
  margin-right: -90px;
  padding-left: 90px;
  padding-right: 90px;
}
@media only screen and (min-width: 1200px) and (max-width: 1650px) {
  .newsletter-wrapper-two {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 1199px) {
  .newsletter-wrapper-two {
    margin-left: 0;
    margin-right: 0;
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .newsletter-wrapper-two {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 450px) {
  .newsletter-wrapper-two {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.newsletter-wrapper-two .shape-svg {
  position: absolute;
  top: 0;
  right: 0;
}
.newsletter-wrapper-two .newsletter-form .form_control {
  margin-bottom: 20px;
}
.newsletter-wrapper-two .newsletter-image-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 1199px) {
  .newsletter-wrapper-two .newsletter-image-box {
    display: none;
  }
}
.newsletter-wrapper-two .newsletter-image-box .newsletter-image-one {
  min-width: 430px;
  height: 400px;
  border-radius: 43% 57% 60% 40%/47% 54% 46% 53%;
  margin-left: -50px;
}
.newsletter-wrapper-two .newsletter-image-box .circle-border span {
  position: absolute;
  top: -20px;
  left: -80px;
  width: 415px;
  height: 415px;
  border: 1px solid #18a0ca;
  border-radius: 48% 52% 62% 38%/44% 51% 49% 56%;
  -webkit-animation: fintex-rotate-animate 10s linear infinite;
          animation: fintex-rotate-animate 10s linear infinite;
  z-index: -1;
}

.newsletter-from-two .form_group {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.102);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px) {
  .newsletter-from-two .form_group {
    padding: 0;
    border: none;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.newsletter-from-two .form_group .input-box {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
@media (max-width: 767px) {
  .newsletter-from-two .form_group .input-box {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
}
.newsletter-from-two .form_group .input-box i {
  position: absolute;
  top: 7px;
  left: 30px;
  color: #fff;
}
@media (max-width: 767px) {
  .newsletter-from-two .form_group .input-box i {
    top: 18px;
  }
}
.newsletter-from-two .form_group .input-box:not(:last-child) .form_control {
  border-right: 1px solid rgba(255, 255, 255, 0.102);
}
.newsletter-from-two .form_group .input-box .form_control {
  padding-left: 55px;
  height: 30px;
  background-color: transparent;
  color: #898989;
}
.newsletter-from-two .form_group .input-box .form_control::-webkit-input-placeholder {
  color: #898989;
}
.newsletter-from-two .form_group .input-box .form_control::-moz-placeholder {
  color: #898989;
}
.newsletter-from-two .form_group .input-box .form_control:-ms-input-placeholder {
  color: #898989;
}
.newsletter-from-two .form_group .input-box .form_control::-ms-input-placeholder {
  color: #898989;
}
.newsletter-from-two .form_group .input-box .form_control::placeholder {
  color: #898989;
}
@media (max-width: 767px) {
  .newsletter-from-two .form_group .input-box .form_control {
    border-right: none;
    border: 1px solid rgba(255, 255, 255, 0.102);
    height: 50px;
    margin-bottom: 30px;
  }
}

/*====== Error 404 Section CSS ======*/
.error-404-section {
  background: url(../../public/assets/images/gallery/error-after.png);
  background-repeat: no-repeat;
  background-position: 50%;
  border: 1px solid rgba(26, 26, 26, 0.149);
  margin: 70px;
}
.error-404-section .shape {
  position: absolute;
}
.error-404-section .shape.shape-one {
  left: 10%;
  top: 25%;
}
.error-404-section .shape.shape-two {
  left: 10%;
  bottom: 20%;
}
.error-404-section .shape.shape-three {
  right: 11%;
  top: 30%;
}
.error-404-section .shape.shape-four {
  right: 7%;
  bottom: 30%;
}
@media (max-width: 450px) {
  .error-404-section {
    margin: 15px;
  }
}
.error-404-section .error-404-wrapper .error-content h2 {
  margin-bottom: 23px;
}
.error-404-section .error-404-wrapper .error-content p {
  padding: 0 27%;
  margin-bottom: 35px;
}
@media (max-width: 450px) {
  .error-404-section .error-404-wrapper .error-content p {
    padding: 0;
  }
}
.error-404-section .error-404-wrapper .error-content .error-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.error-404-section .error-404-wrapper .error-content .error-button .call-button {
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: #1a1a1a;
}
.error-404-section .error-404-wrapper .error-content .error-button .call-button:hover {
  color: #db812e;
}
.error-404-section .error-404-wrapper .error-content .error-button .call-button i {
  margin-right: 10px;
  color: #db812e;
  font-size: 30px;
}

/*====== User Section CSS ======*/
.fintex-user-section .user-wrapper {
  max-width: 430px;
  margin: auto;
}
.fintex-user-section .user-wrapper .user-content {
  background-color: #fff;
  -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 18px rgba(0, 0, 0, 0.06);
  padding: 30px;
}
.fintex-user-section .user-wrapper .user-content form.user-form .form_control {
  padding-left: 63px;
  border: 1px solid #e3e3e3;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.fintex-user-section .user-wrapper .user-content form.user-form .form_control:focus {
  border-color: #db812e;
}
.fintex-user-section .user-wrapper .user-content form.user-form i {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 40px;
  height: 30px;
  color: #db812e;
  border-right: 1px solid #e3e3e3;
}
.fintex-user-section .user-wrapper .user-content form.user-form .main-btn {
  width: 100%;
  text-transform: uppercase;
}

/*====== Pagination CSS ======*/
.fintex-pagination ul li {
  display: inline-block;
  margin-left: 2.5px;
  margin-right: 2.5px;
}
.fintex-pagination ul li a {
  width: 60px;
  height: 60px;
  border: 2px solid rgba(26, 26, 26, 0.102);
  border-radius: 50%;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
  color: #1a1a1a;
  font-weight: 800;
}
@media (max-width: 767px) {
  .fintex-pagination ul li a {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }
}
.fintex-pagination ul li a:hover {
  background-color: #db812e;
  color: #fff;
  border-color: transparent;
}
.fintex-pagination ul li span {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: rgba(26, 26, 26, 0.302);
  border-radius: 50%;
  margin: 0 4px;
}

/*---==================
    08. Portfolio css 
=================----*/
.projects-slider-one {
  margin-left: -15px;
  margin-right: -15px;
}
.projects-slider-one .slick-track {
  padding-bottom: 30px;
}
.projects-slider-one .project-item {
  padding-left: 15px;
  padding-right: 15px;
}

.projects-slider-two {
  margin-left: -15px;
  margin-right: -15px;
}
.projects-slider-two .project-item-five, .projects-slider-two .project-item-two {
  padding-left: 15px;
  padding-right: 15px;
}
.projects-slider-two ul.slick-dots {
  text-align: center;
}

.project-item:hover .img-holder .project-hover {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}
.project-item .img-holder {
  position: relative;
}
.project-item .img-holder img {
  width: 100%;
}
.project-item .img-holder .project-hover {
  position: absolute;
  bottom: 0;
  right: 25px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project-item .img-holder .hover-content {
  position: relative;
  max-width: 255px;
  padding: 30px 30px 20px;
  background-color: #db812e;
}
.project-item .img-holder .hover-content .icon-btn {
  position: absolute;
  top: -22px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 18px;
  color: #db812e;
}
.project-item .img-holder .hover-content .icon-btn:hover {
  background-color: #1a1a1a;
  color: #fff;
}
.project-item .img-holder .hover-content .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  max-width: 50px;
  margin-right: 20px;
}
.project-item .img-holder .hover-content .icon i {
  font-size: 50px;
  color: #fff;
  line-height: 1;
}
.project-item .img-holder .hover-content .text h3.title {
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}
.project-item .img-holder .hover-content .text h3.title:hover {
  color: #1a1a1a;
}

.project-item-two:hover .project-content .icon-btn i {
  background-color: #0dceb5;
  color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(13, 206, 181, 0.5);
          box-shadow: 0px 10px 60px 0px rgba(13, 206, 181, 0.5);
}
.project-item-two .img-holder img {
  width: 100%;
}
.project-item-two .project-content {
  position: relative;
  padding-top: 20px;
}
.project-item-two .project-content h3.title:hover {
  color: #0dceb5;
}
.project-item-two .project-content .icon-btn {
  position: absolute;
  top: -30px;
  right: 25px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fff;
}
.project-item-two .project-content .icon-btn i {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(159, 159, 159, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(159, 159, 159, 0.3);
  color: #0dceb5;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.project-filter .filter-btn li {
  display: inline-block;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #74787c;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project-filter .filter-btn li span.number {
  vertical-align: super;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.project-filter .filter-btn li.active {
  color: #db812e;
}
.project-filter .filter-btn li.active span.number {
  visibility: visible;
  opacity: 1;
}

.project-item-three:hover .project-content {
  background-color: #db812e;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}
.project-item-three:hover .project-content h3.title {
  color: #fff;
}
.project-item-three:hover .project-content .cat-btn {
  color: #fff;
}
.project-item-three:hover .project-content .icon-btn {
  top: -22px;
  visibility: visible;
  opacity: 1;
}
.project-item-three .img-holder img {
  width: 100%;
}
.project-item-three .project-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  background-color: #fff;
  padding: 20px 30px 25px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project-item-three .project-content h3.title {
  letter-spacing: -0.03em;
}
.project-item-three .project-content .icon-btn {
  position: absolute;
  top: -10px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #fff;
  color: #db812e;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.project-item-four:hover .img-holder .project-hover {
  visibility: visible;
  opacity: 1;
}
.project-item-four:hover .img-holder .project-hover .hover-content .icon-btn {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.project-item-four .img-holder {
  position: relative;
  overflow: hidden;
}
.project-item-four .img-holder img {
  width: 100%;
}
.project-item-four .img-holder .project-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 12, 14, 0.8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.project-item-four .img-holder .project-hover .hover-content {
  padding: 23px 20px;
}
.project-item-four .img-holder .project-hover .hover-content h3.title {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.project-item-four .img-holder .project-hover .hover-content p {
  color: #fff;
}
.project-item-four .img-holder .project-hover .hover-content .icon-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #db812e;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.project-item-five .img-holder img {
  width: 100%;
}
.project-item-five .project-content {
  position: relative;
  padding: 23px 30px 28px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
.project-item-five .project-content:after {
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
.project-item-five .project-content h3.title:hover {
  color: #d69b6b;
}

/* Project Details */
@media (max-width: 991px) {
  .project-details-wrapper .project-content h2.title {
    font-size: 32px;
    line-height: 42px;
  }
}
.project-details-wrapper .project-information {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
}
.project-details-wrapper .project-information .item-info h4 {
  margin-bottom: 10px;
}
.project-details-wrapper .project-information .item-info p a:hover {
  color: #db812e;
}
.project-details-wrapper .content-block h3.title {
  font-size: 30px;
  margin-bottom: 17px;
}
.project-details-wrapper .content-block p {
  margin-bottom: 20px;
}

.post-navigation-two .post-nav-item .thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 150px;
          flex: 0 0 150px;
  max-width: 150px;
  margin-right: 40px;
}
@media (max-width: 991px) {
  .post-navigation-two .post-nav-item .thumb {
    margin-right: 20px;
  }
}
.post-navigation-two .post-nav-item .thumb img {
  width: 100%;
}
.post-navigation-two .post-nav-item .content h6 {
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .post-navigation-two .post-nav-item .content h6 {
    font-size: 16px;
    line-height: 28px;
  }
}
.post-navigation-two .post-nav-item .content h6:hover {
  color: #db812e;
}
.post-navigation-two .post-nav-item .content p a:hover {
  color: #db812e;
}
.post-navigation-two .post-nav-item.next-post {
  direction: rtl;
  text-align: right;
}
.post-navigation-two .post-nav-item.next-post .thumb {
  margin-right: 0;
  margin-left: 40px;
}
@media (max-width: 991px) {
  .post-navigation-two .post-nav-item.next-post .thumb {
    margin-left: 20px;
  }
}

/*---==================
    09. FAQ css 
=================----*/
/* Faq Content box */
.faq-one_content-box blockquote {
  border-left: 3px solid #db812e;
  padding-left: 20px;
  margin-bottom: 30px;
}
.faq-one_content-box ul {
  margin-bottom: 35px;
}

/* Faq accordion */
.faq-accordion-one .accordion-card {
  border-radius: 5px;
  border: none;
}
.faq-accordion-one .accordion-card.accordion-active {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.1);
}
.faq-accordion-one .accordion-card .card-header {
  background-color: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}
.faq-accordion-one .accordion-card .card-header h5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 35px;
}
.faq-accordion-one .accordion-card .card-header h5:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
  color: #db812e;
}
.faq-accordion-one .accordion-card .card-header h5:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 22px;
  margin-left: auto;
  color: #db812e;
}
.faq-accordion-one .accordion-card .card-header h5[aria-expanded] {
  background-color: rgba(26, 26, 26, 0.04);
}
.faq-accordion-one .accordion-card .card-header h5[aria-expanded=true] {
  background-color: transparent;
  padding-bottom: 13px;
}
.faq-accordion-one .accordion-card .card-header h5[aria-expanded=true]:after {
  content: "";
}
.faq-accordion-one .accordion-card .card-body {
  padding: 0 35px 20px;
}

.faq-accordion-two .accordion-card {
  border-radius: 5px;
  border: none;
  border: 1px solid rgba(26, 26, 26, 0.071);
  border-bottom: 1px solid rgba(26, 26, 26, 0.071);
  cursor: pointer;
}
.faq-accordion-two .accordion-card .card-header {
  padding: 0;
  border: none;
  background-color: transparent;
}
.faq-accordion-two .accordion-card .card-header h5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 18px 35px;
  border-radius: 5px;
}
.faq-accordion-two .accordion-card .card-header h5:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
  color: #db812e;
}
.faq-accordion-two .accordion-card .card-header h5:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 22px;
  margin-left: auto;
  color: #db812e;
}
.faq-accordion-two .accordion-card .card-header h5[aria-expanded=true] {
  padding: 15px 35px 8px;
}
.faq-accordion-two .accordion-card .card-header h5[aria-expanded=true]:after {
  content: "";
}
.faq-accordion-two .accordion-card .card-body {
  padding: 0 35px 20px;
}
.faq-accordion-two .accordion-card.accordion-active {
  background-color: #fff;
  border-color: transparent;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(115, 115, 115, 0.1);
}

.faq-accordion-three {
  background-color: #fff;
}
.faq-accordion-three .accordion-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 70px 32px;
}
@media (max-width: 991px) {
  .faq-accordion-three .accordion-card {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 25px 30px 32px;
  }
}
.faq-accordion-three .accordion-card:not(:last-child) {
  border-bottom: 1px solid rgba(24, 28, 34, 0.071);
}
.faq-accordion-three .accordion-card .card-header {
  padding: 0;
  background-color: transparent;
  border: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
          flex: 0 0 250px;
  max-width: 250px;
  margin-right: 10%;
  cursor: pointer;
}
@media (max-width: 991px) {
  .faq-accordion-three .accordion-card .card-header {
    margin-right: 0;
  }
}
.faq-accordion-three .accordion-card .card-header h5 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}
.faq-accordion-three .accordion-card .card-header h5:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 22px;
  margin-left: auto;
  color: #fff;
  position: absolute;
  right: 70px;
  top: 35px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1a1a1a;
  cursor: pointer;
}
@media (max-width: 450px) {
  .faq-accordion-three .accordion-card .card-header h5:after {
    right: 20px;
  }
}
.faq-accordion-three .accordion-card .card-header h5[aria-expanded=true]:after {
  background-color: #0dceb5;
  color: #fff;
  content: "";
}
.faq-accordion-three .accordion-card .card-body {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60%;
          flex: 0 0 60%;
  max-width: 60%;
  padding: 0;
}
@media (max-width: 1199px) {
  .faq-accordion-three .accordion-card .card-body {
    padding-top: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-accordion-three .accordion-card .card-body {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
            flex: 0 0 80%;
    max-width: 80%;
  }
}
@media (max-width: 991px) {
  .faq-accordion-three .accordion-card .card-body {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
}

.faq-accordion-four .accordion-card {
  background-color: #fff;
  padding: 0 30px;
}
.faq-accordion-four .accordion-card .card-header {
  padding: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
}
.faq-accordion-four .accordion-card .card-header h5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 20px 0;
  border-radius: 5px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.faq-accordion-four .accordion-card .card-header h5:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
  color: #1a1a1a;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.faq-accordion-four .accordion-card .card-header h5:after {
  content: "";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 22px;
  margin-left: auto;
  color: #db812e;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.faq-accordion-four .accordion-card .card-header h5[aria-expanded=true] {
  border-color: rgba(36, 40, 121, 0.102);
}
.faq-accordion-four .accordion-card .card-header h5[aria-expanded=true]:before {
  content: "";
  color: #db812e;
}
.faq-accordion-four .accordion-card .card-header h5[aria-expanded=true]:after {
  content: "";
}
.faq-accordion-four .accordion-card .card-body {
  padding: 19px 0;
}

.single-faq-card {
  padding: 30px 40px;
  border: 1px solid rgba(26, 26, 26, 0.071);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-faq-card:hover {
  border-color: #db812e;
}
.single-faq-card:hover .text h5.title {
  color: #db812e;
}
.single-faq-card .text h5.title {
  margin-bottom: 5px;
}

/* Faq Image box */
.faq-one_image-box {
  max-width: 500px;
}
.faq-one_image-box .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.3);
}
.faq-one_image-box .play-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.faq-one_image-box .play-content .video-popup:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

.faq-two_image-box {
  min-height: 670px;
  max-width: 580px;
}
.faq-two_image-box .faq-one-img {
  top: 0;
  right: 0;
}
.faq-two_image-box .faq-two-img {
  left: 0;
  bottom: 0;
}
.faq-two_image-box .faq-three-img {
  right: -80px;
  bottom: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .faq-two_image-box .faq-three-img {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .faq-two_image-box .faq-three-img {
    right: 0;
  }
}
.faq-two_image-box .faq-one-img, .faq-two_image-box .faq-two-img, .faq-two_image-box .faq-three-img {
  position: absolute;
}
@media (max-width: 450px) {
  .faq-two_image-box .faq-one-img, .faq-two_image-box .faq-two-img, .faq-two_image-box .faq-three-img {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
  }
}
.faq-two_image-box .fintex-logo {
  position: absolute;
  top: 150px;
  left: 35px;
}

.faq-section-bg {
  position: relative;
  z-index: 1;
}
.faq-section-bg .pattern-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/*---==================
    10. Product css 
=================----*/
ul.ratings li {
  display: inline-block;
  font-size: 13px;
}
ul.ratings li i {
  color: #db812e;
}

.product-search-filter .product-search .form_control {
  background-color: #fafafa;
  font-size: 18px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}
.product-search-filter .product-search .form_control::-webkit-input-placeholder {
  color: #1a1a1a;
}
.product-search-filter .product-search .form_control::-moz-placeholder {
  color: #1a1a1a;
}
.product-search-filter .product-search .form_control:-ms-input-placeholder {
  color: #1a1a1a;
}
.product-search-filter .product-search .form_control::-ms-input-placeholder {
  color: #1a1a1a;
}
.product-search-filter .product-search .form_control::placeholder {
  color: #1a1a1a;
}
.product-search-filter .product-search .search-btn {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  position: absolute;
  color: #fff;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #db812e;
}
@media (max-width: 767px) {
  .product-search-filter .show-text {
    text-align: center;
    margin-bottom: 20px;
  }
}
.product-search-filter .show-text p {
  color: #1a1a1a;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}
.product-search-filter .filter-category {
  float: right;
}
@media (max-width: 767px) {
  .product-search-filter .filter-category {
    float: none;
    text-align: center;
  }
}
.product-search-filter .filter-category > ul > li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.product-search-filter .filter-category > ul > li:not(:last-child) {
  margin-right: 15px;
}
.product-search-filter .filter-category > ul > li .nice-select {
  border: 1px solid rgba(44, 44, 44, 0.102);
  padding: 0 25px;
  font-size: 15px;
  font-weight: 700;
  color: #1a1a1a;
  padding-right: 40px;
}
.product-search-filter .filter-category > ul > li .nice-select:after {
  right: 25px;
}
.product-search-filter .filter-category > ul > li a {
  font-size: 20px;
  color: #1a1a1a;
}
.product-search-filter .filter-category > ul > li a:hover {
  color: #db812e;
}

.single-product-item {
  position: relative;
  background-color: #fafafa;
  padding: 40px 35px 30px;
}
.single-product-item:hover .product-img .cart-btn {
  bottom: 0%;
  visibility: visible;
  opacity: 1;
}
.single-product-item span.status {
  position: absolute;
  top: 15px;
  right: 20px;
  font-weight: 800;
  background-color: #db812e;
  color: #fff;
  font-family: "Manrope", sans-serif;
  line-height: 16px;
  padding: 5px 10px;
  border-radius: 5px;
}
.single-product-item .product-img {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.single-product-item .product-img .cart-btn {
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 7px 25px;
  background-color: #db812e;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  min-width: 130px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-product-item .product-info h3.title {
  font-size: 18px;
  line-height: 30px;
}
.single-product-item .product-info h3.title:hover {
  color: #db812e;
}
.single-product-item .product-info span.price {
  font-weight: 700;
  color: #db812e;
  font-family: "Manrope", sans-serif;
}
.single-product-item .product-info span.price span.prev-price {
  color: #a4a4a4;
  text-decoration: line-through;
  margin-right: 5px;
}

/* Product Details CSS */
.product-details-wrapper .product-gallery-area {
  margin-right: 40px;
}
@media (max-width: 1199px) {
  .product-details-wrapper .product-gallery-area {
    margin-right: 0;
  }
}
.product-details-wrapper .product-info h3.title {
  margin-bottom: 10px;
}
.product-details-wrapper .product-info .products-rating-price {
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(44, 44, 44, 0.102);
  margin-bottom: 30px;
}
.product-details-wrapper .product-info .products-rating-price ul.ratings li {
  color: #db812e;
}
.product-details-wrapper .product-info .products-rating-price span.price {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-left: 10px;
}
.product-details-wrapper .product-info > p {
  margin-bottom: 40px;
}
.product-details-wrapper .product-info .product-meta {
  padding-top: 30px;
  border-top: 1px solid rgba(44, 44, 44, 0.102);
  border-bottom: 1px solid rgba(44, 44, 44, 0.102);
  padding-bottom: 35px;
  margin-bottom: 40px;
}
.product-details-wrapper .product-info .product-meta li {
  font-weight: 600;
  margin-bottom: 0;
  font-family: "Manrope", sans-serif;
}
.product-details-wrapper .product-info .product-meta li:last-child {
  margin-bottom: 0;
}
.product-details-wrapper .product-info .product-meta li span {
  color: #1a1a1a;
  font-size: 18px;
  margin-right: 20px;
  font-weight: 800;
}
.product-details-wrapper .product-info .product-cart ul li {
  display: inline-block;
}
.product-details-wrapper .product-info .product-cart ul li:not(:last-child) {
  margin-right: 10px;
}
.product-details-wrapper .product-info .product-cart ul li input[type=number] {
  padding: 0 20px;
  width: 90px;
  height: 48px;
  border: 1px solid rgba(31, 30, 23, 0.071);
}
@media (max-width: 767px) {
  .product-details-wrapper .product-info .product-cart ul li input[type=number] {
    width: 70px;
  }
}
.product-details-wrapper .product-info .product-cart ul li .main-btn {
  padding: 12px 30px;
  line-height: 26px;
}
.product-details-wrapper .product-info .product-cart ul li .main-btn:after {
  display: none;
}
@media (max-width: 767px) {
  .product-details-wrapper .product-info .product-cart ul li .main-btn {
    padding: 12px 14px;
  }
}
.product-details-wrapper .product-info .product-cart ul li .wishlist-btn {
  width: 60px;
  height: 50px;
  font-size: 15px;
  border-radius: 5px;
  color: #1a1a1a;
  border: 1px solid rgba(31, 30, 23, 0.071);
}
.product-details-wrapper .product-info .product-cart ul li .wishlist-btn:hover {
  background-color: #db812e;
  color: #fff;
  border-color: transparent;
}
@media (max-width: 767px) {
  .product-details-wrapper .product-info .product-cart ul li .wishlist-btn {
    width: 50px;
    height: 50px;
  }
}

.product-gallery-area .product-thumb-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.product-gallery-area .product-thumb-slider .product-img {
  cursor: pointer;
  margin-left: 15px;
  margin-right: 15px;
}

.description-area .description-tabs .nav-link {
  padding: 15px 60px;
  border: 2px solid #db812e;
  border-radius: 5px;
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 800;
  margin-right: 15px;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .description-area .description-tabs .nav-link {
    padding: 15px 30px;
    font-size: 16px;
  }
}
.description-area .description-tabs .nav-link.active {
  background-color: #db812e;
  border-color: transparent;
  color: #fff;
}

.review-form h3.title {
  font-size: 30px;
  line-height: 40px;
}
.review-form form .ratings li span {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.review-form form .ratings li i {
  font-size: 17px;
  color: #db812e;
}
.review-form form .nice-select, .review-form form .form_control {
  background-color: #fafafa;
  margin-bottom: 30px;
  border-radius: 5px;
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 800;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.review-form form .form_control:focus {
  border-color: #1a1a1a;
  background-color: transparent;
}
.review-form form .form_control::-webkit-input-placeholder {
  color: #1a1a1a;
}
.review-form form .form_control::-moz-placeholder {
  color: #1a1a1a;
}
.review-form form .form_control:-ms-input-placeholder {
  color: #1a1a1a;
}
.review-form form .form_control::-ms-input-placeholder {
  color: #1a1a1a;
}
.review-form form .form_control::placeholder {
  color: #1a1a1a;
}
.review-form form .nice-select {
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
}
.review-form form .nice-select.open {
  border-color: #1a1a1a;
  background-color: transparent;
}
.review-form form .nice-select:after {
  right: 25px;
}

.recent-product-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.recent-product-slider .single-product-item {
  margin-left: 15px;
  margin-right: 15px;
}

/*---==================
    11. Team css 
=================----*/
.team-slider-one {
  margin-left: -15px;
  margin-right: -15px;
}
.team-slider-one .single-team-item {
  padding-left: 15px;
  padding-right: 15px;
}
.team-slider-one ul.slick-dots {
  text-align: center;
}

.single-team-item .img-holder {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
}
.single-team-item .img-holder img {
  width: 100%;
}
.single-team-item .img-holder:hover .social-link {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.single-team-item .img-holder .team-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
}
.single-team-item .img-holder .team-shape path {
  fill: #db812e;
  mix-blend-mode: multiply;
}
.single-team-item .img-holder .share-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: #fff;
  font-size: 24px;
  color: #db812e;
  cursor: pointer;
}
.single-team-item .img-holder .social-link {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px 10px;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-team-item .img-holder .social-link li {
  display: block;
}
.single-team-item .img-holder .social-link li a {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #db812e;
}
.single-team-item .img-holder .social-link li a:hover {
  background-color: #db812e;
  color: #fff;
}
.single-team-item .text {
  padding-top: 30px;
}
.single-team-item .text h3.title:hover {
  color: #db812e;
}
.single-team-item .text p.position {
  font-weight: 500;
  color: #db812e;
  padding-bottom: 15px;
}
.single-team-item .text p.mail {
  position: relative;
  padding-top: 18px;
}
.single-team-item .text p.mail:hover a {
  color: #db812e;
}
.single-team-item .text p.mail i {
  margin-right: 10px;
  color: #db812e;
}
.single-team-item .text p.mail:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background-color: rgba(26, 26, 26, 0.1);
}

.single-team-item-two {
  position: relative;
  background-color: #fff;
  padding: 40px;
  border: 1px solid rgba(24, 28, 34, 0.102);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-team-item-two:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(185, 185, 185, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(185, 185, 185, 0.2);
}
.single-team-item-two .img-holder {
  margin-bottom: 30px;
}
.single-team-item-two .img-holder img {
  width: 175px;
  height: 175px;
  border-radius: 50%;
}
.single-team-item-two .text h3.title {
  font-size: 22px;
  letter-spacing: -0.03em;
}
.single-team-item-two .text h3.title:hover {
  color: #0dceb5;
}
.single-team-item-two .text p.posiiton {
  font-weight: 500;
}

.single-team-item-three:hover .img-holder .social-box {
  bottom: 35px;
}
.single-team-item-three .img-holder {
  position: relative;
  overflow: hidden;
}
.single-team-item-three .img-holder img {
  width: 100%;
}
.single-team-item-three .img-holder .social-box {
  position: absolute;
  bottom: -100%;
  right: 25px;
  background-color: #d69b6b;
  padding: 4px 15px;
  -webkit-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.single-team-item-three .img-holder .social-box ul.social-link li {
  margin-left: 5px;
  margin-right: 5px;
}
.single-team-item-three .img-holder .social-box ul.social-link li a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.single-team-item-three .img-holder .social-box ul.social-link li a:hover {
  color: #ffff;
}
.single-team-item-three .text {
  position: relative;
  padding: 12px 20px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
  margin-left: 25px;
  margin-right: 25px;
  margin-top: -35px;
}
.single-team-item-three .text h3.title {
  font-size: 18px;
  line-height: 28px;
}
.single-team-item-three .text h3.title:hover {
  color: #d69b6b;
}
.single-team-item-three .text p.position {
  font-weight: 500;
  color: #d69b6b;
  font-family: "DM Sans", sans-serif;
}

.team-one_content-box h2 {
  margin-bottom: 20px;
}
.team-one_content-box h2 span.thin {
  font-weight: 300;
  display: block;
}
.team-one_content-box p {
  margin-bottom: 25px;
}
.team-one_content-box .skill-wrap-one {
  background-color: #1a1a1a;
  padding: 50px 60px 20px;
  margin-left: -220px;
}
@media (max-width: 991px) {
  .team-one_content-box .skill-wrap-one {
    margin-left: 0;
  }
}

.author-section .author-image {
  margin-top: -80px;
}
.author-section .author-content-box h2 {
  margin-bottom: 20px;
}
.author-section .author-content-box h2 span.thin {
  font-weight: 300;
  display: block;
}
.author-section .author-content-box p {
  font-size: 24px;
  line-height: 35px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 20px;
}
.about-content-box p {
  font-size: 18px;
  line-height: 35px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 20px;
}

.author-section .author-content-box h5 {
  color: #db812e;
}
.author-section .author-content-box h5:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  width: 40px;
  height: 3px;
  background-color: #db812e;
  vertical-align: middle;
  margin-right: 20px;
}

/*---==================
    12. Testimonial css 
=================----*/
.border-bottom-1 {
  border-bottom: 1px solid rgba(26, 26, 26, 0.071);
}

ul.slick-dots li {
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 50%;
}
ul.slick-dots li button::before {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #fff;
}
ul.slick-dots li.slick-active {
  border-color: #db812e;
}

ul.rating li {
  display: inline-block;
}

.testimonial-section-two {
  position: relative;
  z-index: 1;
}

.testimonial-area{
  background-color: #03320e;
}

.testimonial-section-two:after {
  position: absolute;
  top: 118px;
  content: "";
  width: 100%;
  height: 1000px;
  background-color: #edf2f5;
  z-index: -1;
}

.testimonial-section-variable-width .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1199px) {
  .testimonial-section-variable-width .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.testimonial-slider-two {
  margin-left: -15px;
  margin-right: -15px;
}
.testimonial-slider-two .single-testimonial-item {
  margin-left: 15px;
  margin-right: 15px;
}
.testimonial-slider-two .slick-dots {
  text-align: center;
}

.testimonial-wrapper-one {
  position: relative;
  z-index: 2;
  padding: 60px 50px 70px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(26, 26, 26, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(26, 26, 26, 0.1);
}
.testimonial-wrapper-one:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 70px;
  background: url(../../public/assets/images/shape/testimonial-shape-1.png) no-repeat center;
  background-size: cover;
  z-index: -1;
}

.testimonial-wrapper-two {
  margin-left: -115px;
  margin-right: -115px;
  padding: 70px 115px;
}
@media (max-width: 1199px) {
  .testimonial-wrapper-two {
    margin-right: 0;
    margin-left: 0;
    padding: 70px 35px;
  }
}
.testimonial-wrapper-two .testimonial-dots {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: rotate(90deg) translateY(-50%);
  transform: rotate(90deg) translateY(-50%);
}
.testimonial-wrapper-two.dark-black-bg {
  background-color: #03320e;
}
.testimonial-wrapper-two.dark-black-bg .testimonial-item-two .testimonial-content ul.ratings li span.title {
  color: #bed88f;
}
.testimonial-wrapper-two.dark-black-bg .testimonial-item-two .testimonial-content .author-thumb-title .author-title h4 {
  color: #fff;
}
.testimonial-wrapper-two.dark-black-bg .testimonial-item-two .testimonial-content .author-thumb-title .author-title p.position {
  color: rgba(255, 255, 255, 0.5);
}
.testimonial-wrapper-two.dark-black-bg ul.slick-dots li.slick-active {
  border-color: #0dceb5;
}
.testimonial-wrapper-two.dark-black-bg ul.slick-dots li:after {
  background-color: #fff;
}

.testimonial-wrapper-three .map-img-box {
  margin-right: -40px;
}

.map-img-box .thumb-marker {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
  width: 70px;
  height: 90px;
  background: url(../../public/assets/images/testimonial/marker-blue.png) no-repeat;
  background-size: cover;
}
.map-img-box .thumb-marker img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
}

.testimonial-item .quote > i {
  color: #db812e;
  font-size: 50px;
}
.testimonial-item .testimonial-content > p {
  font-size: 22px;
  line-height: 35px;
  margin-bottom: 18px;
}
.testimonial-item .testimonial-content ul.ratings {
  margin-bottom: 27px;
}
.testimonial-item .testimonial-content ul.ratings li span.title {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  margin-right: 15px;
}
.testimonial-item .testimonial-content .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  max-width: 60px;
  margin-right: 20px;
}
.testimonial-item .testimonial-content .author-thumb-title .author-thumb img {
  width: 100%;
  border-radius: 50%;
}
.testimonial-item .testimonial-content .author-thumb-title .author-title p.position {
  color: #db812e;
}

@media (max-width: 991px) {
  .testimonial-item-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.testimonial-item-two .quote {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
  max-width: 70px;
  margin-right: 50px;
}
.testimonial-item-two .quote i {
  font-size: 50px;
  color: #db812e;
}
.testimonial-item-two .testimonial-content ul.ratings {
  margin-bottom: 10px;
}
.testimonial-item-two .testimonial-content ul.ratings li span.title {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  margin-right: 15px;
}
.testimonial-item-two .testimonial-content > p {
  font-size: 22px;
  line-height: 35px;
  margin-bottom: 18px;
}
.testimonial-item-two .testimonial-content .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  max-width: 60px;
  margin-right: 20px;
}
.testimonial-item-two .testimonial-content .author-thumb-title .author-thumb img {
  width: 100%;
  border-radius: 50%;
}
.testimonial-item-two .testimonial-content .author-thumb-title .author-title p.position {
  color: #db812e;
  font-weight: 500;
}

.author-rating-box {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.102);
}
.author-rating-box .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50px;
          flex: 0 0 50px;
  max-width: 50px;
  margin-right: 15px;
}
.author-rating-box .author-thumb-title .author-thumb img {
  width: 100%;
  border-radius: 50%;
}
.author-rating-box .author-thumb-title .author-title h4 {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}
.author-rating-box .author-rating h6 {
  color: #fff;
}
.author-rating-box .author-rating ul.rating li i {
  color: #db812e;
  font-size: 10px;
}

.author-rating-box-two .rating li span {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  margin-right: 10px;
}
.author-rating-box-two .rating li i {
  color: #db812e;
}
.author-rating-box-two h5 {
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 15px;
}
.author-rating-box-two .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 40px;
          flex: 0 0 40px;
  max-width: 40px;
  margin-right: 10px;
}
.author-rating-box-two .author-thumb-title .author-thumb img {
  width: 100%;
  border-radius: 50%;
}
.author-rating-box-two .author-thumb-title .author-title h5 {
  color: #007eff;
  margin-bottom: 0;
}

.testimonial-item-three {
  padding: 40px 50px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(97, 142, 188, 0.10196)), color-stop(60%, rgba(39, 57, 76, 0.21)), to(rgba(1, 1, 1, 0.32157)));
  background: linear-gradient(-180deg, rgba(97, 142, 188, 0.10196) 0%, rgba(39, 57, 76, 0.21) 60%, rgba(1, 1, 1, 0.32157) 100%);
}
.testimonial-item-three .testimonial-content > p {
  margin-bottom: 25px;
}
.testimonial-item-three .author-rating-box {
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.102);
}

.single-testimonial-item {
  padding: 40px 45px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(26, 26, 26, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(26, 26, 26, 0.1);
  margin-bottom: 60px;
}
.single-testimonial-item .quote-ratings .quote i {
  color: #db812e;
  font-size: 50px;
}
.single-testimonial-item .testimonial-content > p {
  margin-bottom: 20px;
}
.single-testimonial-item .testimonial-content .author-thumb-title .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  max-width: 60px;
  margin-right: 20px;
}
.single-testimonial-item .testimonial-content .author-thumb-title .author-thumb img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.single-testimonial-item .testimonial-content .author-thumb-title .author-title p.position {
  font-weight: 500;
  color: #db812e;
}

.testimonial-slider-four {
  margin-left: -15px;
  margin-right: -15px;
}
.testimonial-slider-four .slick-track {
  padding: 60px 0;
}
.testimonial-slider-four .slick-dots {
  text-align: center;
}
.testimonial-slider-four .slick-dots li.slick-active {
  border-color: #18a0ca;
}
.testimonial-slider-four .slick-dots li:after {
  background-color: #18a0ca;
}
.testimonial-slider-four .testimonial-item-four {
  margin-left: 15px;
  margin-right: 15px;
  width: 4%;
}

.testimonial-item-four {
  position: relative;
  z-index: 1;
}
.testimonial-item-four:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 97%;
  height: 110%;
  border: 1px solid #d69b6b;
  z-index: -1;
}
@media (max-width: 767px) {
  .testimonial-item-four:after {
    display: none;
  }
}
.testimonial-item-four .testimonial-inner-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 43px 50px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
@media (max-width: 767px) {
  .testimonial-item-four .testimonial-inner-content {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 43px 30px;
  }
}
.testimonial-item-four .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 130px;
          flex: 0 0 130px;
  max-width: 130px;
  margin-right: 40px;
}
@media (max-width: 767px) {
  .testimonial-item-four .author-thumb {
    margin-bottom: 20px;
  }
}
.testimonial-item-four .author-thumb img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.testimonial-item-four .testimonial-content p {
  color: #1a1a1a;
  font: 500 22px "Manrope", sans-serif;
  line-height: 38px;
  margin-bottom: 20px;
}
.testimonial-item-four .testimonial-content h5.author-title:before {
  display: inline-block;
  content: "";
  width: 40px;
  height: 3px;
  background-color: #d69b6b;
  vertical-align: middle;
  margin-right: 20px;
}
.testimonial-item-four .testimonial-content h5.author-title span.position {
  font-size: 16px;
  color: #74787c;
  font-size: 400;
}

@media (max-width: 991px) {
  .testimonial-item-five .testimonial-img {
    margin-bottom: 40px;
  }
}
.testimonial-item-five .testimonial-img img {
  border-radius: 12px;
}
.testimonial-item-five .testimonial-content img.company-logo {
  margin-bottom: 30px;
}
.testimonial-item-five .testimonial-content > p {
  font: 500 22px "Manrope", sans-serif;
  line-height: 38px;
  color: #1a1a1a;
  margin-bottom: 25px;
}
.testimonial-item-five .testimonial-content .author-title {
  position: relative;
  padding-left: 60px;
}
.testimonial-item-five .testimonial-content .author-title:before {
  position: absolute;
  top: 10px;
  left: 0;
  content: "";
  width: 40px;
  height: 3px;
  background-color: #d69b6b;
}
.testimonial-item-five .testimonial-content .author-title h4 {
  line-height: 1;
  margin-bottom: 5px;
  font-weight: 800;
}

.testimonial-dots-green .slick-dots li.slick-active {
  border-color: #48bb0f;
}
.testimonial-dots-green .slick-dots li:after {
  background-color: #48bb0f;
}

/*---==================
    13. Blog css 
=================----*/
/* Post Meta */
.post-meta ul li {
  display: inline-block;
  margin-bottom: 10px;
}
.post-meta ul li:not(:last-child):after {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  background-color: rgba(26, 26, 26, 0.302);
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 15px;
}
.post-meta ul li a {
  font-size: 18px;
}
.post-meta ul li a:hover {
  color: #db812e;
}

/* Blog Section */
.blog-section {
  overflow: hidden;
}
.blog-section .shape {
  position: absolute;
  z-index: -1;
}
.blog-section .shape.shape-one {
  bottom: 0;
  left: 0;
}
.blog-section .shape.shape-two {
  top: -230px;
  right: -170px;
}
.blog-section .shape.shape-two span {
  width: 500px;
  height: 500px;
  border: 100px solid #db812e;
  border-radius: 50%;
}

/* Blog Post Item */
.blog-post-item-one {
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(109, 109, 109, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(109, 109, 109, 0.1);
  border-radius: 20px;
}

.blog-post-item-one .post-thumbnail {
  position: relative;
}
.blog-post-item-one .post-thumbnail img {
  width: 100%;
}
.blog-post-item-one .post-thumbnail .post-date {
  position: absolute;
  bottom: -15px;
  right: 20px;
}
.blog-post-item-one .post-thumbnail .post-date a {
  text-align: center;
  background-color: #db812e;
  color: #fff;
  padding: 10px 20px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 800;
}
.blog-post-item-one .post-thumbnail .post-date a span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
}
.blog-post-item-one .entry-content {
  padding: 30px 40px 30px;
}
@media (max-width: 991px) {
  .blog-post-item-one .entry-content {
    padding: 30px;
  }
}
.blog-post-item-one .entry-content h3.title {
  letter-spacing: -0.03em;
  margin-bottom: 13px;
}
.blog-post-item-one .entry-content h3.title:hover {
  color: #db812e;
}
.blog-post-item-one .entry-content p {
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .blog-post-item-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.blog-post-item-two .entry-content, .blog-post-item-two .post-thumbnail {
  width: 50%;
}
@media (max-width: 1199px) {
  .blog-post-item-two .entry-content, .blog-post-item-two .post-thumbnail {
    width: 100%;
  }
}
.blog-post-item-two .post-thumbnail {
  position: relative;
}
.blog-post-item-two .post-thumbnail img {
  width: 100%;
}
.blog-post-item-two .post-thumbnail .post-date {
  position: absolute;
  top: 25px;
  right: 40px;
}
.blog-post-item-two .post-thumbnail .post-date a {
  text-align: center;
  background-color: #db812e;
  color: #fff;
  padding: 10px 20px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 800;
}
.blog-post-item-two .post-thumbnail .post-date a span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
}
.blog-post-item-two .entry-content {
  position: relative;
  margin-left: -40px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 40px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(109, 109, 109, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(109, 109, 109, 0.1);
}
@media (max-width: 1199px) {
  .blog-post-item-two .entry-content {
    margin: 0;
  }
}
.blog-post-item-two .entry-content h3.title:hover {
  color: #db812e;
}

.blog-post-item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid rgba(26, 26, 26, 0.071);
  padding: 40px;
}
@media (max-width: 767px) {
  .blog-post-item-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.blog-post-item-content.post-content-bg {
  position: relative;
  z-index: 1;
}
.blog-post-item-content.post-content-bg:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.85);
  mix-blend-mode: multiply;
  z-index: -1;
}
@media (max-width: 767px) {
  .blog-post-item-content .post-date {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 20px;
  }
}
.blog-post-item-content .post-date a {
  text-align: center;
  background-color: #db812e;
  color: #fff;
  padding: 10px 20px;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  font-weight: 800;
}
.blog-post-item-content .post-date a span {
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
}
.blog-post-item-content .entry-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .blog-post-item-content .entry-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-right: 0px;
  }
}
.blog-post-item-content .entry-content h3.title {
  margin-bottom: 10px;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
}
.blog-post-item-content .entry-content h3.title:hover {
  color: #db812e;
}
.blog-post-item-content .entry-content > p {
  margin-bottom: 10px;
}

.img-loading {
  filter: blur(10px);
  clip-path: inset(0);
}
.img-loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

/* Blog Details */
.blog-details-wrapper .blog-post {
  padding: 25px 40px 30px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 80px 0px rgba(109, 109, 109, 0.1);
          box-shadow: 0px 10px 80px 0px rgba(109, 109, 109, 0.1);
}
.blog-details-wrapper .blog-post .main-post .entry-content h3.title {
  font-size: 27px;
  line-height: 40px;
  letter-spacing: -0.03em;
  padding-bottom: 35px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.07);
  margin-bottom: 30px;
}
.blog-details-wrapper .blog-post .main-post .entry-content p {
  margin-bottom: 30px;
}
.blog-details-wrapper .blog-post .main-post .entry-content .block-image {
  margin-bottom: 30px;
}
.blog-details-wrapper .blog-post .main-post .entry-content .quote-admin {
  padding: 30px 40px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fafafa;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .blog-post .main-post .entry-content .quote-admin {
    width: 95%;
  }
}
.blog-details-wrapper .blog-post .main-post .entry-content .quote-admin .quote-inner-content .quote-admin-content h3 {
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .blog-post .main-post .entry-content .quote-admin .quote-inner-content .quote-admin-content h3 {
    font-size: 18px;
    line-height: 30px;
  }
}
.blog-details-wrapper .blog-post .main-post .entry-content .quote-admin .quote-inner-content .quote-admin-content h5 {
  color: #db812e;
}
@media (max-width: 767px) {
  .blog-details-wrapper .blog-post .main-post .entry-content .quote-admin .quote-inner-content .quote-admin-content h5 {
    font-size: 16px;
  }
}
.blog-details-wrapper .blog-post .entry-footer {
  border-top: 1px solid rgba(26, 26, 26, 0.07);
  padding-top: 33px;
}
.blog-details-wrapper .blog-post .entry-footer h4 {
  font-size: 18px;
  margin-right: 10px;
}
.blog-details-wrapper .blog-post .entry-footer .tag-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
.blog-details-wrapper .blog-post .entry-footer .tag-links a {
  padding: 5px 14px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.2);
  background-color: #fff;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  color: #1a1a1a;
  margin-left: 10px;
  margin-bottom: 10px;
}
.blog-details-wrapper .blog-post .entry-footer .tag-links a:hover {
  background-color: #db812e;
  color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(243, 77, 30, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(243, 77, 30, 0.2);
}
.blog-details-wrapper .blog-post .entry-footer .social-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-details-wrapper .blog-post .entry-footer .social-share a {
  margin-left: 15px;
}
.blog-details-wrapper .blog-post .entry-footer .social-share a:hover {
  color: #db812e;
}
.blog-details-wrapper .post-author-box {
  padding: 40px 50px 30px;
  border: 1px solid rgba(26, 26, 26, 0.102);
}
@media (max-width: 767px) {
  .blog-details-wrapper .post-author-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.blog-details-wrapper .post-author-box .author-thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 150px;
          flex: 0 0 150px;
  max-width: 150px;
  margin-right: 30px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .post-author-box .author-thumb {
    margin-bottom: 20px;
  }
}
.blog-details-wrapper .post-author-box .author-thumb img {
  width: 100%;
}
.blog-details-wrapper .post-author-box .author-content h4 {
  margin-bottom: 8px;
}
.blog-details-wrapper .post-author-box .author-content p {
  margin-bottom: 5px;
}
.blog-details-wrapper .post-author-box .author-content ul.social-link li:not(:last-child) {
  margin-right: 20px;
}
.blog-details-wrapper .post-author-box .author-content ul.social-link li a:hover {
  color: #db812e;
}
.blog-details-wrapper .post-navigation .post-nav-item {
  max-width: 280px;
}
.blog-details-wrapper .post-navigation .post-nav-item .thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
  max-width: 70px;
  height: 70px;
  margin-right: 25px;
}
.blog-details-wrapper .post-navigation .post-nav-item .thumb img {
  width: 100%;
}
.blog-details-wrapper .post-navigation .post-nav-item .content {
  margin-top: -5px;
}
.blog-details-wrapper .post-navigation .post-nav-item .content .post-date a i {
  margin-right: 10px;
}
.blog-details-wrapper .post-navigation .post-nav-item .content h6:hover {
  color: #db812e;
}
.blog-details-wrapper .post-navigation .post-nav-item.next-post {
  direction: rtl;
  float: right;
  text-align: right;
}
.blog-details-wrapper .post-navigation .post-nav-item.next-post .thumb {
  margin-right: 0;
  margin-left: 25px;
}
.blog-details-wrapper .post-navigation .post-nav-item.next-post .content .post-date a i {
  margin-right: 0px;
  margin-left: 10px;
}
.blog-details-wrapper .comments-area {
  padding: 30px 50px 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 80px 0px rgba(109, 109, 109, 0.1);
          box-shadow: 0px 10px 80px 0px rgba(109, 109, 109, 0.1);
}
@media (max-width: 767px) {
  .blog-details-wrapper .comments-area {
    padding: 30px 20px 10px;
  }
}
.blog-details-wrapper .comments-area h4.comments-title {
  margin-bottom: 42px;
}
.blog-details-wrapper .comments-area .comments-list {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .comments-area .comments-list {
    margin-left: 0;
  }
}
.blog-details-wrapper .comments-area .comments-list .comment {
  background-color: #fafafa;
  padding: 20px 40px 25px;
}
.blog-details-wrapper .comments-area .comments-list .comment:nth-child(even) {
  margin-left: 75px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .comments-area .comments-list .comment:nth-child(even) {
    margin-left: 25px;
  }
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content span.date i {
  margin-right: 10px;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content span.time {
  margin-left: 15px;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content p {
  margin-bottom: 10px;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content .comment-author-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content .comment-author-title img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content .comment-author-title .author-name {
  font-size: 20px;
  font-weight: 700;
  color: #1a1a1a;
  margin-left: 15px;
}
@media (max-width: 767px) {
  .blog-details-wrapper .comments-area .comments-list .comment .comment-wrap .comment-author-content .comment-author-title .author-name {
    font-size: 16px;
  }
}
.blog-details-wrapper .comments-respond h4 {
  letter-spacing: -0.03em;
  margin-bottom: 10px;
}
.blog-details-wrapper .comments-respond p {
  margin-bottom: 20px;
}
.blog-details-wrapper .comments-respond .comment-form .form_control {
  background-color: #fafafa;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  font-family: "Manrope", sans-serif;
}

/* Sidebar Widget */
.sidebar-widget {
  padding: 30px;
  border-radius: 5px;
  border: 1px solid rgba(26, 26, 26, 0.102);
}
.sidebar-widget h4.widget-title {
  margin-bottom: 30px;
}
.sidebar-widget h4.widget-title span.line {
  display: block;
  width: 35px;
  height: 3px;
  background-color: #db812e;
  position: relative;
  margin: 12px 0 8px;
}
.sidebar-widget h4.widget-title span.line:before, .sidebar-widget h4.widget-title span.line:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #db812e;
}
.sidebar-widget h4.widget-title span.line:before {
  right: -16px;
  width: 10px;
}
.sidebar-widget h4.widget-title span.line:after {
  right: -29px;
  width: 5px;
}
.sidebar-widget.search-widget {
  background-color: #fafafa;
}
.sidebar-widget.search-widget form .form_control {
  -webkit-box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.1);
  font-style: italic;
  height: 55px;
}
.sidebar-widget.search-widget form .search-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #db812e;
  color: #fff;
  width: 55px;
  height: 55px;
}
.sidebar-widget.category-widget h4.widget-title {
  margin-bottom: 22px;
}
.sidebar-widget.category-widget .category-nav li:not(:last-child) a {
  border-bottom: 1px solid rgba(26, 26, 26, 0.071);
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.sidebar-widget.category-widget .category-nav li a {
  display: block;
  font-size: 18px;
  font-weight: 700;
}
.sidebar-widget.category-widget .category-nav li a span {
  float: right;
}
.sidebar-widget.category-widget .category-nav li a:hover {
  color: #db812e;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.071);
  margin-bottom: 30px;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80px;
          flex: 0 0 80px;
  max-width: 80px;
  height: 70px;
  margin-right: 25px;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content .post-title-date {
  margin-top: -7px;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content .post-title-date span.posted-on {
  font-weight: 500;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content .post-title-date span.posted-on i {
  margin-right: 10px;
}
.sidebar-widget.recent-post-widget .recent-post-list .post-thumbnail-content .post-title-date h6:hover {
  color: #db812e;
}
.sidebar-widget.contact-info-widget {
  position: relative;
  min-height: 440px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  z-index: 1;
}
.sidebar-widget.contact-info-widget:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.3);
  z-index: -1;
  mix-blend-mode: multiply;
}
.sidebar-widget.contact-info-widget .contact-info-content {
  padding: 40px;
}
.sidebar-widget.contact-info-widget .contact-info-content span {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 10px;
}
.sidebar-widget.contact-info-widget .contact-info-content span:before {
  display: inline-block;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 10px;
}
.sidebar-widget.contact-info-widget .contact-info-content h3.title {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  letter-spacing: -0.03em;
  margin-bottom: 32px;
}
.sidebar-widget .Rectangle_14 {
  background-color: #1a1a1a;
  opacity: 0.302;
  position: absolute;
  left: 1175px;
  top: 2250px;
  width: 370px;
  height: 450px;
  z-index: 189;
}
.sidebar-widget.tag-cloud-widget {
  background-color: #fafafa;
}
.sidebar-widget.tag-cloud-widget a {
  padding: 8px 20px;
  background-color: #fff;
  color: #fff;
  line-height: 24px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  color: #1a1a1a;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.2);
          box-shadow: 0px 10px 60px 0px rgba(94, 94, 94, 0.2);
  font-weight: 700;
}
.sidebar-widget.tag-cloud-widget a:hover {
  background-color: #db812e;
  color: #fff;
}
.sidebar-widget.recent-products-widget .product-item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(26, 26, 26, 0.071);
}
.sidebar-widget.recent-products-widget .product-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-widget.recent-products-widget .product-item .thumb {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
          flex: 0 0 70px;
  max-width: 70px;
  margin-right: 15px;
}
.sidebar-widget.recent-products-widget .product-item .info h6.title {
  font-size: 18px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
}
.sidebar-widget.product-category-widget ul.category-nav li a {
  display: block;
  font-weight: 500;
  color: #74787c;
}
.sidebar-widget.product-category-widget ul.category-nav li a i {
  margin-right: 10px;
}
.sidebar-widget.product-category-widget ul.category-nav li a span {
  vertical-align: super;
  margin-left: 5px;
}
.sidebar-widget.price-range-widget .price-number input {
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
}
.sidebar-widget.price-range-widget .ui-widget-content {
  height: 3px;
  border: none;
  background-color: rgba(20, 29, 56, 0.1);
}
.sidebar-widget.price-range-widget .ui-slider-range {
  background-color: #db812e;
  height: 3px;
}
.sidebar-widget.price-range-widget .ui-slider-horizontal .ui-slider-handle {
  margin-left: 0;
  height: 15px;
  width: 3px;
  border: none;
  background-color: #db812e;
  outline: none;
}
.sidebar-widget.product-tag-cloud a {
  font-size: 15px;
  padding: 3px 15px;
  background-color: rgba(26, 26, 26, 0.05);
  color: #1a1a1a;
  line-height: 25px;
  border-radius: 3px;
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  margin-bottom: 7px;
}
.sidebar-widget.product-tag-cloud a:hover {
  background-color: #db812e;
  color: #fff;
}

/*---==================
    14. Contact css 
=================----*/
/* Contact Item */
.contact-info-box .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20px;
          flex: 0 0 20px;
  max-width: 20px;
  margin-right: 10px;
}
.contact-info-box .icon i {
  color: #db812e;
}
.contact-info-box .text {
  margin-top: -3px;
}

.single-info-item {
  padding: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #add34e;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 450px) {
  .single-info-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 30px 30px;
    text-align: center;
  }
}
.single-info-item:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(124, 124, 124, 0.1);
          box-shadow: 0px 10px 60px 0px rgba(124, 124, 124, 0.1);
}
.single-info-item:hover .icon img {
  -webkit-animation: shake linear 0.3s infinite;
          animation: shake linear 0.3s infinite;
}
.single-info-item .icon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 60px;
          flex: 0 0 60px;
  max-width: 60px;
  margin-right: 30px;
}
.single-info-item .info h3.title {
  margin-bottom: 5px;
}
.single-info-item .info p a:hover {
  color: #db812e;
}

/* Map box */
.map-box iframe {
  display: inherit;
  border: none;
  height: 300px;
  width: 100%;
}

/* Contact Content box */
.contact-one_content-box > p {
  margin-bottom: 25px;
}
.contact-one_content-box .social-box h3.title {
  margin-bottom: 15px;
}
.contact-one_content-box .social-box ul.social-link li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(26, 26, 26, 0.102);
  margin-right: 5px;
}
.contact-one_content-box .social-box ul.social-link li a:hover {
  background-color: #db812e;
  border-color: transparent;
  color: #fff;
}

/* Contact Form box */
.contact-one_form-box .contact-form .form_control::-webkit-input-placeholder {
  color: #74787c;
}
.contact-one_form-box .contact-form .form_control::-moz-placeholder {
  color: #74787c;
}
.contact-one_form-box .contact-form .form_control:-ms-input-placeholder {
  color: #74787c;
}
.contact-one_form-box .contact-form .form_control::-ms-input-placeholder {
  color: #74787c;
}
.contact-one_form-box .contact-form .form_control::placeholder {
  color: #74787c;
}
.contact-one_form-box .contact-form .form_control:focus {
  border-color: #1a1a1a;
}
.contact-one_form-box .contact-form .nice-select, .contact-one_form-box .contact-form .form_control {
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  padding: 0 33px;
  margin-bottom: 40px;
}
.contact-one_form-box .contact-form textarea.form_control {
  height: 100px;
}
.contact-one_form-box .contact-form i {
  position: absolute;
  top: 10px;
  left: 0;
}
.contact-one_form-box .contact-form .form_checkbox {
  margin-bottom: 30px;
}

.contact-two_form-box {
  background-color: #fafafa;
  padding: 65px 70px 80px;
  margin-top: 50px;
  margin-left: -100px;
}
@media (max-width: 991px) {
  .contact-two_form-box {
    margin-left: 0;
    padding: 65px 30px 80px;
  }
}
.contact-two_form-box form.contact-form .form_checkbox {
  margin-bottom: 20px;
}
.contact-two_form-box form.contact-form i {
  position: absolute;
  top: 17px;
}
.contact-two_form-box form.contact-form .form_control {
  height: 50px;
  background-color: transparent;
  border-bottom: 1px solid rgba(26, 26, 26, 0.102);
  margin-bottom: 30px;
}
.contact-two_form-box form.contact-form textarea.form_control {
  height: 80px;
}

.contact-three_form-box {
  background-color: #fff;
  padding: 60px 70px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
          box-shadow: 0px 10px 60px 0px rgba(205, 205, 205, 0.3);
}
@media (max-width: 1199px) {
  .contact-three_form-box {
    padding: 60px 30px;
  }
}
.contact-three_form-box .contact-form label {
  color: #1a1a1a;
  font: 700 18px "Manrope", sans-serif;
  margin-bottom: 15px;
}
.contact-three_form-box .contact-form .form_control {
  background-color: #fcf9f7;
  margin-bottom: 32px;
}

/* Contact Section */
.contact-section-two .contact-two_form-box {
  margin-bottom: -92%;
  margin-top: 0;
}

.contact-section-three .pattern-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.contact-section-three .contact-content-box.text-white .sub-title {
  color: #fff;
}
.contact-section-three .contact-three_form-box {
  margin-bottom: -40px;
}
@media (max-width: 991px) {
  .contact-section-three .contact-three_form-box {
    margin-top: 50px;
  }
}

/*---==================
    15. Footer css 
=================----*/
.border-top-white-op-1 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.border-top-1 {
  border-top: 1px solid rgba(36, 40, 121, 0.102);
}

.footer-widget {
  margin-top: -8px;
}
.footer-widget h4.widget-title {
  font-size: 20px;
  margin-bottom: 25px;
}

.footer-content > p {
  margin-bottom: 20px;
}
.footer-content ul.widget-nav li {
  line-height: 1;
}
.footer-content ul.widget-nav li:not(:last-child) a {
  margin-bottom: 25px;
}
.footer-content ul.widget-nav li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
}
.footer-content ul.widget-nav li a span.status {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 10px;
  background-color: #fafafa;
  border-radius: 5px;
  margin-left: 8px;
}
.footer-content ul.widget-nav li a:hover {
  color: #db812e;
}
.footer-content ul.widget-nav li a:before {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  content: "";
  font-family: "Font Awesome 5 pro";
  font-weight: 400;
  margin-right: 17px;
}
.footer-content .social-link li:not(:first-child) {
  margin-left: 5px;
}
.footer-content .social-link li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.5);
  background-color: #262626;
  font-size: 14px;
}
.footer-content .social-link li a:hover {
  background-color: #db812e;
  color: #fff;
}
.footer-content .contact-info-box .text p:hover a {
  color: #db812e;
}

.about-company-widget-two {
  padding-right: 10%;
}
.about-company-widget-two .footer-content .footer-logo {
  margin-bottom: 20px;
}

.about-info-widget .footer-widget-logo {
  padding-bottom: 40px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 20px;
}
.about-info-widget h5.title {
  margin-bottom: 10px;
}

.contact-info-widget h5.title {
  margin-bottom: 30px;
}

.footer-social-box ul.social-link li:not(:last-child) {
  margin-right: 5px;
}
.footer-social-box ul.social-link li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  border: 1px solid rgba(255, 255, 255, 0.102);
}

.footer-copyright {
  padding: 25px 0;
}
@media (max-width: 1199px) {
  .footer-copyright .footer-nav {
    text-align: center;
  }
}
.footer-copyright .footer-nav ul li {
  display: inline-block;
}
.footer-copyright .footer-nav ul li:not(:last-child):after {
  margin-left: 20px;
  margin-right: 15px;
  display: inline-block;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.3);
}
.footer-copyright .footer-nav ul li a {
  color: #adadad;
  position: relative;
}
.footer-copyright .footer-nav ul li a:hover:after {
  width: 100%;
}
.footer-copyright .footer-nav ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background-color: #fff;
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
.footer-copyright .footer-nav ul li a:hover {
  color: #fff;
}
@media (max-width: 1199px) {
  .footer-copyright .footer-text {
    text-align: center;
  }
}
.footer-copyright .footer-text p span.number {
  color: #fff;
  font-size: 20px;
}

.footer-default {
  background-color: #8eb850;
  overflow: hidden;
}
.footer-default .shape {
  position: absolute;
  z-index: -1;
}
.footer-default .shape.shape-one {
  bottom: -300px;
  left: -250px;
}
.footer-default .shape.shape-one span {
  width: 600px;
  height: 600px;
  border-radius: 50%;
  border: 120px solid rgba(250, 250, 250, 0.02);
}
.footer-default .shape.shape-two {
  bottom: 0;
  right: 0;
}

.footer-two {
  background-color: #181c22;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.footer-two .shape {
  position: absolute;
  z-index: -1;
}
.footer-two .shape span {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 1px solid rgba(116, 120, 124, 0.1);
}
.footer-two .shape.shape-one {
  top: -100px;
  left: 20px;
}
.footer-two .shape.shape-two {
  left: 250px;
  top: -33%;
}
.footer-two .shape.shape-three {
  right: -16%;
  bottom: 30px;
}
.footer-two .shape.shape-four {
  bottom: -45%;
  right: -10%;
}

.footer-three .footer-content ul.widget-nav li a {
  color: #6b6b6b;
}
.footer-three .footer-content ul.widget-nav li a:before {
  display: none;
}
.footer-three .footer-content .social-link li a {
  color: #060815;
  background-color: rgba(36, 40, 121, 0.102);
}

/*---==================
    16. Home Two css 
=================----*/
.home-two .preloader .pre-box {
  background-color: #0dceb5;
}
.home-two span.sub-title {
  color: #0dceb5;
}
.home-two .main-btn.btn-red {
  background-color: #0dceb5;
}
.home-two .main-btn.btn-red:hover {
  background-color: #0f0f0f;
}
.home-two .main-btn.filled-btn {
  color: #0dceb5;
}
.home-two .main-btn.filled-btn:hover {
  background-color: #0dceb5;
  color: #fff;
}
.home-two .main-btn.filled-btn.filled-white {
  color: #fff;
}
.home-two .main-btn.filled-btn.filled-white:hover {
  color: #1a1a1a;
}
.home-two .header-navigation .main-menu ul > li a.active {
  color: #0dceb5;
}
.home-two .header-navigation .main-menu ul > li .sub-menu li:hover > a {
  color: #0dceb5;
}
.home-two .header-navigation .main-menu ul > li .sub-menu li.has-children:hover > a:after {
  color: #0dceb5;
}
.home-two .header-navigation .main-menu ul > li:hover > a {
  color: #0dceb5;
}
.home-two .header-navigation .main-menu ul > li:hover > a:before {
  background-color: #0dceb5;
}
.home-two .header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  background-color: #0dceb5;
}
.home-two .banner-two .hero-content .hero-button .main-btn.btn-white:hover {
  background-color: #1a1a1a;
  color: #fff;
}
.home-two .service-item .text i {
  color: #0dceb5;
}
.home-two .service-item .hover-text {
  background-color: #0dceb5;
}
.home-two .single-counter-item-two .text h2.number {
  color: #0dceb5;
}
.home-two .single-counter-item-two .text h5 {
  color: rgba(255, 255, 255, 0.7);
}
.home-two .features-item-two:hover .icon {
  background-color: #0dceb5;
  color: #fff;
}
.home-two .features-item-two:hover .icon-btn {
  background-color: #0dceb5;
  color: #fff;
}
.home-two .features-item-two .icon {
  color: #0dceb5;
}
.home-two .features-item-two .icon-btn {
  color: #0dceb5;
}
.home-two .cta-section-two .section-title h2 span {
  color: #0dceb5;
}
.home-two ul.check-style-two li:before {
  background-color: #0dceb5;
}
.home-two .projects-slider-two ul.slick-dots li.slick-active {
  border-color: #0dceb5;
}
.home-two .projects-slider-two ul.slick-dots li.slick-active button::before {
  background-color: #0dceb5;
}
.home-two .blog-post-item-one .post-thumbnail .post-date a {
  background-color: #0dceb5;
}
.home-two .blog-post-item-one .entry-content .post-meta ul li span a:hover {
  color: #0dceb5;
}
.home-two .blog-post-item-one .entry-content h3.title:hover {
  color: #0dceb5;
}
.home-two .blog-post-item-one .entry-content .btn-link {
  color: #0dceb5;
}
.home-two .newsletter-section .newsletter-from form i {
  color: #0dceb5;
}
.home-two .contact-info-box .icon i {
  color: #0dceb5;
}
.home-two .footer-content ul.widget-nav li a:hover {
  color: #0dceb5;
}
.home-two .footer-content ul.social-link li a:hover {
  background-color: #0dceb5;
}

.home-three-dark {
  background-color: #010101;
}
.home-three-dark .preloader .pre-box {
  background-color: #007eff;
}
.home-three-dark span.sub-title {
  color: #007eff;
}
.home-three-dark span.sub-title:after, .home-three-dark span.sub-title:before {
  display: none;
}
.home-three-dark .section-title h2 {
  font-size: 42px;
  line-height: 52px;
}
@media (max-width: 1199px) {
  .home-three-dark .section-title h2 {
    font-size: 32px;
    line-height: 45px;
  }
}
.home-three-dark p {
  color: rgba(255, 255, 255, 0.7);
}
.home-three-dark .main-btn.filled-btn.filled-white {
  border: 1px solid rgba(255, 255, 255, 0.102);
}
.home-three-dark .main-btn.filled-btn.filled-white:hover {
  color: #007eff;
}
.home-three-dark ul.slick-dots li:after {
  background-color: #fff;
}
.home-three-dark ul.slick-dots li.slick-active {
  border-color: #007eff;
}
.home-three-dark .check-style-one li {
  color: rgba(255, 255, 255, 0.7);
}
.home-three-dark .check-style-one li:before {
  color: #007eff;
}
.home-three-dark .author-rating-box-two .rating li span {
  color: #fff;
}
.home-three-dark .author-rating-box-two h5 {
  color: #fff;
}
.home-three-dark .author-rating-box-two .author-thumb-title .author-title h4 {
  color: #007eff;
}
.home-three-dark .navigation-two.sticky {
  background-color: #0a0e12;
}
.home-three-dark .navigation-two.sticky.navigation-white .navbar-toggler {
  border-color: #fff;
}
.home-three-dark .navigation-two.sticky.navigation-white .navbar-toggler span {
  background-color: #fff;
}
.home-three-dark .sign-in-button .btn-link {
  color: #fff;
}
.home-three-dark .sign-in-button .btn-link:hover {
  color: #007eff;
}
.home-three-dark .header-navigation .main-menu ul > li a.active {
  color: #007eff;
}
.home-three-dark .header-navigation .main-menu ul > li .sub-menu li:hover > a {
  color: #007eff;
}
.home-three-dark .header-navigation .main-menu ul > li .sub-menu li.has-children:hover > a:after {
  color: #007eff;
}
.home-three-dark .header-navigation .main-menu ul > li:hover > a {
  color: #007eff;
}
.home-three-dark .header-navigation .main-menu ul > li:hover > a:before {
  background-color: #007eff;
}
.home-three-dark .header-navigation.breakpoint-on.sticky .main-menu ul > li > a {
  color: #1a1a1a;
}
.home-three-dark .header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  background-color: #007eff;
}
.home-three-dark .header-navigation .navbar-toggler span {
  background-color: #fff;
}
.home-three-dark .banner-three .hero-content p {
  color: rgba(255, 255, 255, 0.6);
}
.home-three-dark .single-counter-item-three .text h5 {
  color: #fff;
}
.home-three-dark .service-item-three .text .btn-link:hover {
  color: #007eff;
}
.home-three-dark .features-item-left-box .icon i {
  font-size: 45px;
  color: #007eff;
}
.home-three-dark .contact-info-box .icon i {
  color: #007eff;
}
.home-three-dark .contact-info-box .text p a:hover {
  color: #007eff;
}
.home-three-dark .footer-content ul.widget-nav li a span.status {
  background-color: #007eff;
  color: #fff;
}
.home-three-dark .footer-content ul.widget-nav li a:hover {
  color: #007eff;
}
.home-three-dark .footer-social-box ul.social-link li a:hover {
  background-color: #007eff;
}

.home-four {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 32px;
}
.home-four h1, .home-four h2, .home-four h3, .home-four h4, .home-four h5 {
  font-weight: 800;
}
.home-four span.sub-title {
  color: #d69b6b;
}
.home-four .header-navigation .main-menu ul > li a.active {
  color: #d69b6b;
}
.home-four .header-navigation .main-menu ul > li .sub-menu li:hover > a {
  color: #d69b6b;
}
.home-four .header-navigation .main-menu ul > li .sub-menu li.has-children:hover > a:after {
  color: #d69b6b;
}
.home-four .header-navigation .main-menu ul > li:hover > a {
  color: #d69b6b;
}
.home-four .header-navigation .main-menu ul > li:hover > a:before {
  background-color: #d69b6b;
}
.home-four .header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  background-color: #d69b6b;
}
.home-four .banner-four .hero-content span.tag-line {
  color: #d69b6b;
}
.home-four .service-item-four .icon i {
  color: #d69b6b;
}
.home-four .service-item-four .text h3.title:hover {
  color: #d69b6b;
}
.home-four .features-item-four:hover .icon i {
  color: #d69b6b;
}
.home-four .footer-three .footer-content ul.widget-nav li a {
  color: #6b6b6b;
}
.home-four .footer-three .footer-content ul.widget-nav li a span.status {
  background-color: #18a0ca;
  color: #fff;
}
.home-four .footer-three .footer-content ul.widget-nav li a:hover {
  color: #d69b6b;
}
.home-four .footer-three .footer-content .social-link li a {
  color: #060815;
  background-color: rgba(36, 40, 121, 0.102);
}
.home-four .footer-three .footer-content .social-link li a:hover {
  background-color: #d69b6b;
  color: #fff;
}
.home-four .footer-three .footer-content .contact-info-box .text p:hover a {
  color: #d69b6b;
}

.home-five {
  font-family: "Nunito", sans-serif;
  line-height: 32px;
}
.home-five h1, .home-five h2, .home-five h3, .home-five h4, .home-five h5 {
  font-weight: 800;
}
.home-five span.sub-title {
  color: #d69b6b;
}
.home-five .header-navigation .main-menu ul > li a.active {
  color: #d69b6b;
}
.home-five .header-navigation .main-menu ul > li .sub-menu li:hover > a {
  color: #d69b6b;
}
.home-five .header-navigation .main-menu ul > li .sub-menu li.has-children:hover > a:after {
  color: #d69b6b;
}
.home-five .header-navigation .main-menu ul > li:hover > a {
  color: #d69b6b;
}
.home-five .header-navigation .main-menu ul > li:hover > a:before {
  background-color: #d69b6b;
}
.home-five .header-navigation.breakpoint-on .nav-menu .main-menu ul li .dd-trigger {
  background-color: #d69b6b;
}
.home-five .features-item-five .text .btn-link {
  color: #d69b6b;
}
.home-five .features-item-five .text .btn-link:hover {
  color: #1a1a1a;
}
.home-five .benefits-content-box ul.check-style-two li:before {
  background-color: #48bb0f;
}
.home-five .projects-slider-two .slick-dots li.slick-active {
  border-color: #48bb0f;
}
.home-five .projects-slider-two .slick-dots li:after {
  background-color: #48bb0f;
}
.home-five .footer-three .footer-content ul.widget-nav li a {
  color: #6b6b6b;
}
.home-five .footer-three .footer-content ul.widget-nav li a span.status {
  background-color: #18a0ca;
  color: #fff;
}
.home-five .footer-three .footer-content ul.widget-nav li a:hover {
  color: #d69b6b;
}
.home-five .footer-three .footer-content .social-link li a {
  color: #060815;
  background-color: rgba(36, 40, 121, 0.102);
}
.home-five .footer-three .footer-content .social-link li a:hover {
  background-color: #d69b6b;
  color: #fff;
}


/* Get Insurance */
/*--------------------------------------------------------------
# Get Insurance Four
--------------------------------------------------------------*/
.get-insurance {
  position: relative;
  display: block;
  background-color:#608730;
  padding: 120px 0 190px;
  overflow: hidden;
  z-index: 1;
}

.insurance_form .form_control {
  border-radius: 30px;
}

.get-insurance__shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 275px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  z-index: -1;
}

.get-insurance__shape-2 {
  position: absolute;
  top: -30px;
  right: 0;
  opacity: .60;
  z-index: -1;
}

.get-insurance__shape-2 img {
  width: auto;
}

.get-insurance__main-tab-box {
  position: relative;
  display: block;
}

.get-insurance__main-tab-box .tab-buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 70px;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn {
  position: relative;
  display: inline-block;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn+.tab-btn {
  margin-top: 10px;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .content-box {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #d7d7d7;
  padding: 9px 20px 9px;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .content-box:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -6px;
  transform: translateY(-50%);
  border-left: 6px solid var(--insur-base);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn:hover .content-box:before,
.get-insurance__main-tab-box .tab-buttons .tab-btn.active-btn .content-box:before {
  opacity: 1;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .content-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: var(--insur-base);
  border-radius: 10px;
  z-index: -1;
  transform: scaleY(0.0);
  transform-origin: left;
  transform-style: preserve-3d;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn:hover .content-box:after,
.get-insurance__main-tab-box .tab-buttons .tab-btn.active-btn .content-box:after {
  transform: scale(1.0);
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .icon-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  background-color: var(--insur-white);
  z-index: 1;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .icon-box span {
  position: relative;
  display: inline-block;
  font-size: 22px;
  color: var(--insur-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn:hover .icon-box span,
.get-insurance__main-tab-box .tab-buttons .tab-btn.active-btn .icon-box span {
  color: var(--insur-base);
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .text-box {
  margin-left: 20px;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn .text-box p {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__main-tab-box .tab-buttons .tab-btn:hover .text-box p,
.get-insurance__main-tab-box .tab-buttons .tab-btn.active-btn .text-box p {
  color: var(--insur-white);
}

.get-insurance__main-tab-box .tabs-content {
  position: relative;
  display: block;
  margin-left: -30px;
}

.get-insurance__main-tab-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transform: translateY(35px);
  -ms-transform: translateY(35px);
  transform: translateY(35px);
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}

.get-insurance__main-tab-box .tabs-content .tab.active-tab {
  display: block;
  margin-top: 0px;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.get-insurance__main-content {
  position: relative;
  display: flex;
}

.get-insurance__main-content-left {
  position: relative;
  display: block;
  max-width: 300px;
  width: 100%;
}

.get-insurance__main-content-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.04em;
  margin-bottom: 16px;
}

.get-insurance__main-content-text {
  line-height: 30px;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insurance__call {
  position: relative;
  display: flex;
  align-items: center;
  border-top: 1px solid #d7d7d7;
  padding-top: 40px;
  margin-top: 30px;
}

.get-insurance__call-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 54px;
  background-color: var(--insur-base);
  border-radius: 50%;
  color: var(--insur-white);
  font-size: 23px;
  transition: all 500ms ease;
}

.get-insurance__call-icon i {
  display: inline-flex;
  transform: scale(1);
  transition: transform 500ms ease;
}

.get-insurance__call:hover .get-insurance__call-icon i {
  transform: scale(.9);
}

.get-insurance__call:hover .get-insurance__call-icon {
  background-color: var(--insur-black);
  color: #fff;
}

.get-insurance__call-content {
  margin-left: 20px;
}

.get-insurance__call-content p {
  letter-spacing: var(--insur-letter-spacing);
  line-height: 16px;
  margin-bottom: 6px;
  color: var(--insur-white);
}

.get-insurance__call-content a {
  font-size: 24px;
  font-weight: 700;
  color: var(--insur-black);
  letter-spacing: var(--insur-letter-spacing);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__call-content a:hover {
  color: var(--insur-base);
}

.get-insurance__main-content-right {
  position: relative;
  display: block;
  max-width: 500px;
  width: 100%;
  margin-left: 30px;
}

/* .get-insurance__form {
  position: relative;
  display: block;
} */

.get-insurance__content-box {
  position: relative;
  display: block;
}

.get-insurance__input-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

/* .get-insurance__input-box input[type="text"],
.get-insurance__input-box input[type="email"] {
  height: 60px;
  width: 100%;
  border: 0;
  background-color: var(--insur-white);
  outline: none;
  font-size: 16px;
  color: #838383;
  font-weight: 400;
  padding: 0 30px 0;
  letter-spacing: var(--insur-letter-spacing);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: var(--insur-bdr-radius);
} */

.get-insurance__input-box .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  position: relative;
  display: block;
  width: 100% !important;

}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle::after {
  display: none;
}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle {
  position: relative;
  height: 60px;
  outline: none !important;
  border-radius: var(--insur-bdr-radius);
  border: 0;
  background-color: var(--insur-white) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  color: #838383 !important;
  font-size: 16px;
  line-height: 60px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
  letter-spacing: var(--insur-letter-spacing);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}

.get-insurance__input-box .bootstrap-select>.dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 14px;
  color: #838383;
}

.get-insurance__input-box .bootstrap-select .dropdown-menu>li.selected>a {
  background: var(--insur-black);
}

.get-insurance__input-box .bootstrap-select .dropdown-menu>li>a:hover {
  background: var(--insur-white);
  color: var(--insur-base);
  cursor: pointer;
}

.get-insurance__input-box .bootstrap-select .dropdown-menu {
  border-radius: 10px;
}

.get-insurance__progress {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 23px;
  margin-bottom: 30px;
}

.get-insurance__progress-single {
  position: relative;
  display: block;
}

.get-insurance__progress-title {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insurance__progress .bar {
  position: relative;
  width: 100%;
  height: 12px;
  background-color: var(--insur-white);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 0
}

.get-insurance__progress .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 12px;
  border-radius: 6px;
  background-image: -moz-linear-gradient(0deg, rgb(25, 28, 30) 0%, rgb(255, 73, 74) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(25, 28, 30) 0%, rgb(255, 73, 74) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(25, 28, 30) 0%, rgb(255, 73, 74) 100%);
  -webkit-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.get-insurance .get-insurance__progress-range .irs--flat .irs-bar {
  background-image: linear-gradient(90deg, var(--insur-black) 0%, var(--insur-base) 100%);
}

.get-insurance .get-insurance__progress-range .irs--flat .irs-line {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  border-color: #fff;
}

.get-insurance .get-insurance__progress {
  margin-bottom: 12px;
}

.get-insurance__progress .count-text {
  position: absolute;
  right: -30px;
  bottom: -8px;
  opacity: 0;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(22, 36, 61);
  width: 30px;
  height: 30px;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.get-insurance__progress .bar-inner.counted .count-text {
  opacity: 1;
}

.get-insurance__balance-box {
  position: absolute;
  top: 0;
  right: 0;
}

.get-insurance__balance {
  font-size: 16px;
  color: var(--insur-black);
  font-weight: 700;
  letter-spacing: var(--insur-letter-spacing);
}

.get-insurance__content-bottom {
  position: relative;
  display: block;
}

.get-insurance__btn {
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 17px 50px 17px;
  border-radius: 30px;
}
/*# sourceMappingURL=style.css.map */
.FKVjX {
  padding: 2px 8px;
  border-radius: 30px;
  background-color: #fff;
  color: black;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

/* Tabs */
.nav-pills .nav-link.active {
  color: #fff;
  background-color: #8EB850;
  /* outline: 1px solid white; */
}
.nav-pills .nav-link{
  outline: 1px solid #fff;
  margin-top: 5px;
}

.wp-block-image img {
  height: auto;
}

.blog-post-item-one .post-thumbnail img {
  height: 350px;
}

.get-insurance__shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 275px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  z-index: -1;
}
.contact-one_form-box .contact-form .form_control {
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  padding: 0 33px;
  margin-bottom: 40px;
}

.wp-block-button__link{
  padding:10px;
  color:white
}

.page-item.active .page-link{
  background-color: #d88031;
}

.page-link{
  color:#000
}

.ag-icon {
  display: none;
}

.insurance-form input {
  border: 1px solid green;
  border-radius: 20px;
  margin:10px 0;
}

.insurance-form select {
  border: 1px solid green;
  border-radius: 20px;
  margin:10px 0;
}

.hide-box{
  display: none;
}

.checkout-input{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #8cbd53;
  padding: 5px 10px
}

.checkout-input:focus{
  border: 1px solid #db812e;
  padding: 5px 10px;
  border-radius: 5px;
}